import React, { useState, useEffect } from 'react';
import {
    create_mr_wo_jc_pro_plan, createMaterialRequestPP, updateMaterialRequestPP
} from "../../../api/index";
import { call_sweet_alert, convertDateYYMMDD, formatDateString } from "../../../common/Functions/CommonFunctions"
import { toast } from "react-toastify";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateStepThree, resetState, updateStepTwo } from '../../../slices/production_planning/production_plan_slice';
import { fetchPPByid } from "../../../slices/production_planning/production_plan_by_id_slice";
import { Spinner } from "react-bootstrap";

const schema = yup
    .object()
    .shape({
        // material_req_status: yup.array()
        //     .min(1, "Please select status !")
        //     .required("Please select status !"),
        // material_req_remarks: yup.string().max(1000).notRequired("Please enter remarks !"),
        items: yup.array().of(
            yup.object().shape({
                qty: yup.string()
                    .matches(/^(0|[1-9]\d*)(\.\d+)?$/, 'Enter valid quantity')
                    .required("Quantity is required"),
                material_req_status: yup.array()
                    .min(1, "Please select status !")
                    .required("Please select status !"),
                material_req_remarks: yup.string().max(1000).notRequired("Please enter remarks !"),
            })
        ).required("Atleast one item is required !"),
    })
    .required();

function StepThree(props) {
    const { nextStep, prevStep, prev_data } = props;
    // const StepOneSavedData = JSON.parse(localStorage.getItem("StepOne"));
    // const StepTwoSavedData = JSON.parse(localStorage.getItem("StepTwo"));
    // const StepThreeSavedData = JSON.parse(localStorage.getItem("StepThree"));
    const dispatch = useDispatch();
    const { data: productionplanDetails } = useSelector((state) => state.prod_plan_details);

    const StepOneSavedData = useSelector((state) => state.steps.StepOne);
    const StepTwoSavedData = useSelector((state) => state.steps.StepTwo);
    const StepThreeSavedData = useSelector((state) => state.steps.StepThree);
    const [disableButton, setDisableButton] = useState(false);
    const [rawMateriaList, setRawMaterialList] = useState({ data: [], loading: false });


    const [constLabel, setConstLabel] = useState({});
    const statusList = [{ name: 'Draft' }, { name: "Finalized" }];
    let navigate = useNavigate();
    // const [productStock, setProductStock] = useState({ data: [], loading: false });

    const {
        register,
        handleSubmit,
        formState,
        control,
        watch,
        getValues,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    let { errors } = formState;


    useEffect(() => {
        // setProductStock({ ...productStock, data: [], loading: true });
        console.log("StepOneSavedData", StepOneSavedData);
        console.log("StepTwoSavedData", StepTwoSavedData);
        console.log("StepThreeSavedData", StepThreeSavedData);
        console.log("productionplanDetails", productionplanDetails);

        if (StepThreeSavedData) {
            reset(StepThreeSavedData);
        } else {

            // StepTwoSavedData.subprodListWithBom.data.forEach((val, ind) => {

            //     setValue(`items[${ind}].material_req_status`, [{ name: "Draft" }]);
            // })

            StepTwoSavedData.subprodListWithBom.data.forEach((val, ind) => {
                const mat_req_from_PP = JSON.parse(JSON.stringify(productionplanDetails?.mat_req_list))
                    .sort((a, b) => a.id - b.id);


                const result_ind = mat_req_from_PP.findIndex((item) =>
                    item.material_req_lineitems[0].product_uuid ==
                    val.lineItemDetails.product_id &&
                    item.material_req_lineitems[0].bom_uuid ==
                    val.lineItemDetails.bomid_id);
                console.log("mat_req_from_PP", mat_req_from_PP);

                console.log("result_ind", result_ind);


                if (productionplanDetails?.mat_req_list?.length > 0 && result_ind !== -1) {

                    if (mat_req_from_PP[result_ind] && mat_req_from_PP[result_ind]?.status) {
                        console.log("mat_req_from_PP[result_ind].status",
                            [{ name: mat_req_from_PP[result_ind].status }]
                        );
                        setValue(`items[${ind}].material_req_status`,
                            [{ name: mat_req_from_PP[result_ind].status }]);
                    }
                    else {
                        setValue(`items[${ind}].material_req_status`, [{ name: "Draft" }]);
                    }

                    if (mat_req_from_PP[result_ind] && mat_req_from_PP[result_ind].remarks) {
                        console.log("mat_req_from_PP[result_ind].remarks",
                            mat_req_from_PP[result_ind].remarks);

                        setValue(`items[${ind}].material_req_remarks`,
                            mat_req_from_PP[result_ind].remarks);
                    }
                } else {

                    setValue(`items[${ind}].material_req_status`, [{ name: "Draft" }]);

                }
            })

            setRawMaterialList({ ...rawMateriaList, data: [], loading: true });


            // const tempData = JSON.parse(JSON.stringify(StepTwoSavedData.subprodListWithBom.data));

            // tempData.map(val => {
            //     console.log("val", val);
            //     prev_data?.mat_req_list.forEach(mat_req => {
            //         const foundedMRItem = mat_req.material_req_lineitems.find((mat_item) =>
            //             val.lineItemDetails.product_id === mat_item.product_uuid
            //             && val.lineItemDetails.so_item_uuid === mat_item.sales_order_item_uuid);

            //         console.log("foundedMRItem", foundedMRItem);

            //         if (foundedMRItem) {
            //             val.lineItemDetails.planToRequestQty = foundedMRItem.qty
            //         }
            //     });
            // });

            // console.log("tempData", tempData);


            // if (StepOneSavedData.formData.status[0].name == "Finalized") {
            //     setValue("material_req_status", StepOneSavedData.formData.status);
            // } else {
            //     setValue("material_req_status", [{ name: 'Draft' }]);
            // }
        }


    }, []);

    const onSubmit = (data) => {
        console.log("data", data);
        console.log(StepOneSavedData);
        console.log(StepTwoSavedData);
        setDisableButton(true);



        if (Object.keys(StepOneSavedData).length !== 0 || Object.keys(StepTwoSavedData).length !== 0) {
            if (!StepOneSavedData.formData.employeePlanning) {
                const allZero = data.items.every(item => item.qty == 0);

                if (allZero) {
                    setDisableButton(false);
                    call_sweet_alert("Cannot Proceed", "Atleast one product should have plan to request qty greater than 0");
                    return
                }
            }



            console.log("StepOneSavedData", StepOneSavedData);
            console.log("prev_data", prev_data);
            if (!productionplanDetails || (productionplanDetails && productionplanDetails?.mat_req_list?.length == 0)) {

                const payload = {
                    ignore_available_qty: StepTwoSavedData.ignoreQty,
                    consolidate_items: StepTwoSavedData.consolidate,
                    // required_date: convertDateYYMMDD(new Date(), '-'),
                    required_date: null,
                    material_req_date: convertDateYYMMDD(StepOneSavedData.formData.plan_date, '-'),
                    // material_req_remarks: data.material_req_remarks,
                    // material_req_status: data.material_req_status[0].name,
                    // draft_data: {
                    //     StepOneSavedData: StepOneSavedData
                    // }
                }

                payload.material_req_line_items = [];

                let i = 0;
                console.log("StepTwoSavedData.subprodListWithBom", StepTwoSavedData.subprodListWithBom);

                for (const item of StepTwoSavedData.subprodListWithBom.data) {

                    let obj = {
                        product_uuid: item.lineItemDetails.product_id,
                        bom_uuid: item.lineItemDetails.bomid_id,
                        sales_order_item_uuid: item.lineItemDetails.so_item_uuid,
                        sales_order_uuid: item.lineItemDetails.so_uuid,
                        quantity: data.items[i].qty,
                        material_req_remarks: data.items[i].material_req_remarks,
                        material_req_status: data.items[i].material_req_status[0].name
                    }

                    if (Number(data.items[i].qty) !== 0 && item.lineItemDetails?.checked == true) {
                        // payload.prod_plan_lineitems.push(obj);
                        payload.material_req_line_items.push(obj);
                    }
                    i++;
                }


                payload.production_plan_uuid = StepOneSavedData.prod_plan_uuid;
                console.log("payload", payload);

                dispatch(updateStepThree(data));
                if (payload.material_req_line_items.length > 0) {
                    createMaterialRequestPP(payload).then((res) => {
                        console.log("res", res);
                        toast.success("Material request created successfully", {
                            autoClose: 2000,
                        });
                        // dispatch(resetState());

                        if (res?.data?.data.length > 0) {
                            dispatch(fetchPPByid({
                                uuid: res.data.data?.uuid, with_line_items: true,
                                with_loc: true,
                                with_org: true,
                                with_wo: true,
                                with_mat_req: true
                            }));
                        }

                        setTimeout(() => {
                            setDisableButton(false);
                            if (StepOneSavedData.formData.employeePlanning) {
                                nextStep();
                            } else {
                                navigate(`${process.env.PUBLIC_URL}/pcms/production_planning`);
                            }
                        }, [2000]);

                    }, (err) => {
                        setDisableButton(false);
                        console.log("err", err);
                        toast.error(err.response.data.message, {
                            autoClose: 3000,
                        });
                    });
                } else {
                    if (StepOneSavedData.formData.employeePlanning) {
                        nextStep();
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pcms/production_planning`);
                    }
                }
            } else {
                // payload.production_plan_uuid = StepOneSavedData.prod_plan_uuid;


                const updatePaylaod = {
                    production_plan_uuid: StepOneSavedData.prod_plan_uuid,
                }

                const mat_req_payload = [];

                console.log("StepTwoSavedData.subprodListWithBom", StepTwoSavedData.subprodListWithBom);
                console.log("productionplanDetails", productionplanDetails);

                const all_mat_items = [];
                productionplanDetails.mat_req_list.forEach((mat_req) => {
                    mat_req.material_req_lineitems.forEach((mat_item) => {
                        all_mat_items.push(mat_item);
                    })
                })

                StepTwoSavedData.subprodListWithBom.data.forEach((val, ind) => {
                    if (Number(data.items[ind].qty) !== 0 && val.lineItemDetails.checked) {

                        productionplanDetails.mat_req_list.forEach((mat_req, ind2) => {
                            console.log(mat_req.material_req_lineitems[0].product_uuid, "==", val.lineItemDetails.product_id);
                            if (mat_req.material_req_lineitems[0].sales_order_uuid == val.lineItemDetails.so_uuid &&
                                mat_req.material_req_lineitems[0].sales_order_item_uuid == val.lineItemDetails.so_item_uuid &&
                                mat_req.material_req_lineitems[0].product_uuid == val.lineItemDetails.product_id
                            ) {

                                const mat_req_obj = {
                                    uuid: mat_req.uuid,
                                    material_req_date: convertDateYYMMDD(mat_req.material_req_date, "-"),
                                    required_date: mat_req.required_date,
                                    material_req_status: data.items[ind].material_req_status[0].name,
                                    material_req_remarks: data.items[ind].material_req_remarks,
                                    material_req_line_items: [],
                                }

                                mat_req_obj.material_req_line_items.push({
                                    uuid: mat_req.material_req_lineitems[0]?.uuid,
                                    product_uuid: mat_req.material_req_lineitems[0]?.product_uuid,
                                    sales_order_item_uuid: mat_req.material_req_lineitems[0]?.sales_order_item_uuid,
                                    bom_uuid: mat_req.material_req_lineitems[0]?.bom_uuid,
                                    sales_order_uuid: mat_req.material_req_lineitems[0]?.sales_order_uuid,
                                    quantity: data.items[ind].qty
                                });
                                mat_req_payload.push(mat_req_obj);
                            }


                            // mat_req.material_req_lineitems.forEach((mat_item) => {
                            //     if (mat_item.sales_order_uuid == val.lineItemDetails.so_uuid &&
                            //         mat_item.sales_order_item_uuid == val.lineItemDetails.so_item_uuid &&
                            //         mat_item.product_uuid == val.lineItemDetails.product_id
                            //     ) {


                            //         mat_req_obj.material_req_line_items.push({
                            //             uuid: mat_item?.uuid,
                            //             product_uuid: mat_item?.product_uuid,
                            //             sales_order_item_uuid: mat_item?.sales_order_item_uuid,
                            //             bom_uuid: mat_item?.bom_uuid,
                            //             sales_order_uuid: mat_item?.sales_order_uuid,
                            //             quantity: data.items[ind].qty
                            //         });
                            //         mat_req_payload.push(mat_req_obj);
                            //     }
                            // });

                        });


                        console.log("val", val);

                        const item_present_in_mr_items = all_mat_items.find((item) =>
                            item.sales_order_uuid == val.lineItemDetails.so_uuid &&
                            item.sales_order_item_uuid == val.lineItemDetails.so_item_uuid &&
                            item.product_uuid == val.lineItemDetails.product_id)

                        if (!item_present_in_mr_items) {
                            console.log("val", val);
                            const mat_req_obj = {
                                material_req_date: convertDateYYMMDD(new Date(), '-'),
                                required_date: convertDateYYMMDD(new Date(), '-'),
                                material_req_remarks: data.items[ind].material_req_remarks,
                                material_req_status: data.items[ind].material_req_status[0].name,
                                material_req_line_items: [{
                                    product_uuid: val.lineItemDetails.product_id,
                                    sales_order_item_uuid: val.lineItemDetails.so_item_uuid,
                                    bom_uuid: val.lineItemDetails.bomid_id,
                                    sales_order_uuid: val.lineItemDetails.so_uuid,
                                    quantity: data.items[ind].qty
                                }],
                            }
                            // delete mat_req_obj.uuid;
                            // mat_req_obj.material_req_date = convertDateYYMMDD(new Date(), '-');
                            // mat_req_obj.material_req_remarks = data.material_req_remarks;
                            // mat_req_obj.material_req_status = data.material_req_status[0].name;
                            // mat_req_obj.material_req_line_items =
                            //     [{
                            //         product_uuid: val.lineItemDetails.product_id,
                            //         sales_order_item_uuid: val.lineItemDetails.so_item_uuid,
                            //         sales_order_uuid: val.lineItemDetails.so_uuid,
                            //         quantity: data.items[ind].qty
                            //     }];

                            mat_req_payload.push(mat_req_obj);
                        }

                    }
                })

                console.log("mat_req_payload", mat_req_payload);
                updatePaylaod.material_request = mat_req_payload;

                console.log("updatePaylaod", updatePaylaod);

                dispatch(updateStepThree(data));
                if (updatePaylaod.material_request.length > 0) {
                    updateMaterialRequestPP(updatePaylaod).then((res) => {
                        console.log("res", res);
                        toast.success("Material request updated successfully", {
                            autoClose: 2000,
                        });
                        if (prev_data) {
                            dispatch(fetchPPByid({
                                uuid: prev_data?.uuid, with_line_items: true,
                                with_loc: true,
                                with_org: true,
                                with_wo: true,
                                with_mat_req: true
                            }));
                        }

                        setTimeout(() => {
                            setDisableButton(false);
                            if (StepOneSavedData.formData.employeePlanning) {
                                nextStep();
                            } else {
                                navigate(`${process.env.PUBLIC_URL}/pcms/production_planning`);
                            }
                        }, [2000]);
                    }, (err) => {
                        setDisableButton(false);
                        toast.error(err.response.data.message, {
                            autoClose: 3000,
                        });
                    });
                } else {
                    setDisableButton(false);
                    if (StepOneSavedData.formData.employeePlanning) {
                        nextStep();
                    } else {
                        navigate(`${process.env.PUBLIC_URL}/pcms/production_planning`);
                    }
                }
            }

            // if (StepOneSavedData.formData.employeePlanning) {
            //     dispatch(updateStepThree(data));

            //     // localStorage.setItem("StepThree", JSON.stringify(data));

            // } else {
            //     if (prev_data) {
            //         payload.production_plan_uuid = prev_data.uuid;
            //     } else {
            //         payload.production_plan_uuid = StepOneSavedData.prod_plan_uuid;
            //     }

            // }

        }
    }

    // Helper function to handle the toggle logic
    const toggleChecked = (index, isChecked) => {
        console.log("StepTwoSavedData", StepTwoSavedData);
        const updatedData = JSON.parse(JSON.stringify(
            StepTwoSavedData.subprodListWithBom.data)).map((ele, i) =>
                i === index
                    ? {
                        ...ele,
                        lineItemDetails: {
                            ...ele.lineItemDetails,
                            checked: isChecked,
                        },
                    }
                    : ele
            );

        console.log("updatedData", updatedData);
        // dispatch(updateStepTwo({ ...StepTwoSavedData, subprodListWithBom: { ...StepTwoSavedData.subprodListWithBom, data: updatedData, }, }));

        const stepThreeData = {
            subprodListWithBom: { data: updatedData },
        }

        dispatch(updateStepTwo({
            data: stepThreeData,
            prev_data: productionplanDetails
        }));
    };

    return (
        <React.Fragment>
            <div className='stepThree'>

                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>

                            <div className='d-flex'>
                                <p className='mb-2 mt-1' id='pro_plan_label'>Create Material Request</p>

                            </div>

                            {/* <div className='col-12 col-md-4'>
                                <div className="form-group mt-md-1 mt-3 typeahead-container">
                                    <label
                                        style={{ fontSize: "14px" }}
                                        className="col-form-label roboto-bold"
                                    >
                                        {constLabel?.lbl_mat_req_status ? constLabel.lbl_mat_req_status : "Material Request Status"}
                                        <span className="text-danger"> *</span>
                                    </label>


                                    <Controller
                                        name={`material_req_status`}
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="name"
                                                multiple={false}
                                                options={statusList}
                                                isLoading={false}
                                                placeholder="Select status"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                                // disabled={StepOneSavedData.formData.status[0].name == "Finalized"}
                                                disabled={true}
                                                isInvalid={errors.material_req_status ? true : false}
                                            />
                                        )}
                                    />
                                    {!errors.material_req_status && <span className="arrow-icon"></span>}


                                    {errors.material_req_status && (
                                        <span className="text-danger err-msg">
                                            {errors.material_req_status.message}
                                        </span>
                                    )}

                                </div>
                            </div> */}


                            {/* <div className='col-12 col-md-8'>
                                <div className="form-group  typeahead-container mt-md-1 mt-3">
                                    <label
                                        style={{ fontSize: "14px" }}
                                        className="col-form-label roboto-bold"
                                    >
                                        {constLabel?.lbl_remarks ? constLabel.lbl_remarks : "Material Request Remarks"}

                                    </label>


                                    <textarea className={
                                        errors.material_req_remarks
                                            ? "form-control  is-invalid"
                                            : "form-control "
                                    }
                                        id="remarks"
                                        name="material_req_remarks"
                                        type="text"
                                        placeholder="Enter remarks"
                                        {...register("material_req_remarks")}
                                        rows={1}>

                                    </textarea>
                                    {errors.material_req_remarks && (
                                        <span className="text-danger err-msg">
                                            {errors.material_req_remarks.message}
                                        </span>
                                    )}

                                </div>
                            </div> */}

                            <div className='col-12 mt-4'>
                                <div>
                                    <i><p className="my-2 mb-0"><span className="text-danger roboto-bold">Note - </span>
                                        Plan To Request Qty having 0 and checkbox not selected will be ignored in material request</p></i>
                                </div>


                                {StepTwoSavedData &&
                                    StepTwoSavedData.subprodListWithBom.data.length > 0 ? (
                                    <div className='mt-2 '
                                    // style={{ maxHeight: '50vh', overflowX: "auto" }}
                                    >
                                        {/* <div className="table-responsive"> */}
                                        <table className="table table-bordered">
                                            <thead className="table-grey roboto-bold">
                                                <tr className="">
                                                    <th scope="col">#</th>
                                                    <th scope="col">{"Sr.No"}</th>


                                                    <th scope="col">
                                                        {constLabel?.lbl_product
                                                            ? constLabel.lbl_product
                                                            : "Product"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_bom
                                                            ? constLabel.lbl_bom
                                                            : "BOM"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_req_to_complete_so
                                                            ? constLabel.lbl_req_to_complete_so
                                                            : "Total Required Qty"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_active_po_qty
                                                            ? constLabel.lbl_active_po_qty
                                                            : "Last PO Qty"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_last_po_date
                                                            ? constLabel.lbl_last_po_date
                                                            : "Last PO Date"}
                                                    </th>



                                                    {/* <th scope="col">
                                                        {constLabel?.lbl_active_po_qty
                                                            ? constLabel.lbl_active_po_qty
                                                            : "Active PO Qty"}
                                                    </th> */}

                                                    {/* <th scope="col">
                                                        {constLabel?.lbl_last_po_date
                                                            ? constLabel.lbl_last_po_date
                                                            : "Last PO Date"}
                                                    </th> */}


                                                    <th scope="col">
                                                        {constLabel?.lbl_so_qty
                                                            ? constLabel.lbl_so_qty
                                                            : "Available Qty"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_plan_to_req_qty
                                                            ? constLabel.lbl_plan_to_req_qty
                                                            : "Plan To Request Qty"}
                                                    </th>

                                                    <th scope="col">
                                                        {constLabel?.lbl_mr_status
                                                            ? constLabel.lbl_mr_status
                                                            : "Status"}
                                                    </th>

                                                    <th scope="col" style={{ width: "20%" }}>
                                                        {constLabel?.lbl_mr_remark
                                                            ? constLabel.lbl_mr_remark
                                                            : "Remark"}
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody className="roboto">
                                                {StepTwoSavedData.subprodListWithBom.data.map((val, ind) => (
                                                    <tr key={ind}>
                                                        <td>
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                checked={val.lineItemDetails.checked}
                                                                onChange={(e) => {

                                                                    const qty = getValues(`items[${ind}].qty`);
                                                                    console.log("qty", qty);
                                                                    if (!val.lineItemDetails.checked && qty == 0) {
                                                                        call_sweet_alert("Cannot Select Product",
                                                                            "Plan to request quantity must be greater 0");
                                                                    } else {
                                                                        toggleChecked(ind, e.target.checked);
                                                                    }
                                                                }}
                                                            // disabled={StepTwoSavedData.ignoreQty ?
                                                            //     false
                                                            //     :
                                                            //     (val.lineItemDetails?.availqty)
                                                            //     >= val.lineItemDetails?.qtyRequiredAsPerBom}
                                                            // disabled={StepTwoSavedData.ignoreQty ?
                                                            //     (val.lineItemDetails?.active_po_qty)
                                                            //     >= val.lineItemDetails?.qtyRequiredAsPerBom
                                                            //     :
                                                            //     (val.lineItemDetails?.active_po_qty + val.lineItemDetails?.availqty)
                                                            //     >= val.lineItemDetails?.qtyRequiredAsPerBom}
                                                            />
                                                        </td>
                                                        <td>{parseInt(ind) + 1}</td>

                                                        <td>
                                                            {val.lineItemDetails.productname !== null
                                                                ? val.lineItemDetails.productname
                                                                : "NA"}
                                                        </td>

                                                        <td>
                                                            {val.bom_name ?? "NA"}
                                                        </td>

                                                        <td>
                                                            {val.lineItemDetails?.qtyRequiredAsPerBom ?? "NA"}
                                                        </td>

                                                        <td>
                                                            {val.last_po_qty ?? "NA"}
                                                        </td>
                                                        <td>
                                                            {val.last_po_details?.created_at
                                                                ? formatDateString(val.last_po_details?.created_at)
                                                                : "NA"}
                                                        </td>
                                                        {/* <td>
                                                            {val.lineItemDetails?.active_po_qty ?? "NA"}
                                                        </td> */}

                                                        {/* <td>
                                                            {formatDateString(val.lineItemDetails?.created_at) ?? "NA"}
                                                        </td> */}

                                                        <td>
                                                            {val.lineItemDetails?.availqty ?? "NA"}
                                                        </td>

                                                        <td>
                                                            <input
                                                                type="text"
                                                                className={
                                                                    errors.items?.[ind]?.qty
                                                                        ? "form-control form-control-sm is-invalid"
                                                                        : "form-control form-control-sm"
                                                                }
                                                                id="qty"
                                                                name={`items[${ind}].qty`}
                                                                defaultValue={parseFloat(val.lineItemDetails?.planToRequestQty).toFixed(2)}
                                                                {...register(`items[${ind}].qty`)}
                                                            />

                                                            <span className="text-danger err-msg">
                                                                {errors.items?.[ind]?.qty.message}
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <Controller
                                                                name={`items[${ind}].material_req_status`}
                                                                control={control}
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <Typeahead
                                                                        id="basic-typeahead-single"
                                                                        labelKey="name"
                                                                        multiple={false}
                                                                        options={statusList}
                                                                        isLoading={false}
                                                                        placeholder="Select status"
                                                                        onChange={onChange}
                                                                        onBlur={onBlur}
                                                                        selected={value}
                                                                        // disabled={StepOneSavedData.formData.status[0].name == "Finalized"}
                                                                        disabled={true}
                                                                        isInvalid={errors.items?.[ind]?.material_req_status ?
                                                                            true : false}
                                                                    />
                                                                )}
                                                            />
                                                            <span className="text-danger err-msg">
                                                                {errors.items?.[ind]?.material_req_status?.message}
                                                            </span>
                                                        </td>

                                                        <td>
                                                            <textarea
                                                                className={
                                                                    errors.items?.[ind]?.material_req_remarks
                                                                        ? "form-control  is-invalid"
                                                                        : "form-control "
                                                                }
                                                                id="remarks"
                                                                name={`items[${ind}].material_req_remarks`}
                                                                type="text"
                                                                placeholder="Enter remarks"
                                                                {...register(`items[${ind}].material_req_remarks`)}
                                                                rows={1}>

                                                            </textarea>

                                                            <span className="text-danger err-msg">
                                                                {errors.items?.[ind]?.material_req_remarks?.message}
                                                            </span>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/* </div> */}
                                    </div>
                                ) :
                                    < div
                                        className="alert alert-danger mb-0 text-center my-4"
                                        role="alert"
                                    >
                                        BOM Sub Items not found !
                                    </div>
                                }
                            </div>



                        </div>


                        <div className='d-flex justify-content-end mt-4'>
                            <button className='btn btn-grey px-4'
                                onClick={() => prevStep()}
                            >Previous</button>

                            <button
                                disabled={disableButton}
                                className='btn button-primary px-4 ms-0 ms-md-2'
                                onClick={handleSubmit(onSubmit)}
                            >Save & Proceed {disableButton &&
                                <Spinner animation="border" size="sm" className='ms-2' />}</button>


                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default StepThree