// features/routeOprSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import client2 from "../../constant/NodeAPI_AxiosInstance";
import { prod_config } from "../../constant/index"
import { assignDatesToOperationsReverse } from "../../common/Functions/CommonFunctions";

// Fetch routes for a selected product
export const fetchRoutesOpr = createAsyncThunk("routes/fetchRouteOperations",
    async ({ payload, startdate, enddate, selected_route, already_saved_opr_list, StepOneSavedData }) => {
        console.log(payload);
        console.log(startdate);
        console.log(enddate);
        console.log(selected_route);
        console.log(StepOneSavedData);

        payload.order = "DESC";
        try {
            const response = await client2.get(prod_config, { params: payload });
            console.log("response", response);
            console.log("already_saved_opr_list", already_saved_opr_list);

            if (response.data.data) {
                let sortedOprSeq = []
                try {
                    sortedOprSeq = response.data.data.sort((a, b) =>
                        parseInt(b.sequenceNo) - parseInt(a.sequenceNo));
                } catch (err) {
                    sortedOprSeq = response.data.data;
                }

                const newOperations = assignDatesToOperationsReverse([...sortedOprSeq],
                    startdate, enddate, already_saved_opr_list, StepOneSavedData);
                console.log("newOperations", newOperations);
                return { operation_list: newOperations, selected_route: selected_route };
            } else {

                return { operation_list: [], selected_route: selected_route };
            }
        } catch (error) {
            console.log("error", error);
            return { operation_list: [], selected_route: selected_route };
        }

    });

const routeOprSlice = createSlice({
    name: "operation_list",
    initialState: {
        data: [],
        selected_route: null,
        selected_opr: null,
        status: "idle",
        error: null,
    },
    reducers: {
        set_global_route_opr(state, action) {
            console.log("action.payload", action.payload);
            state.data = action.payload.operlist;
            state.selected_route = action.payload.selected_global_route;

            if (!state.selected_opr) {
                state.selected_opr = action.payload.operlist[0];
            }
        },
        handle_date_change(state, action) {
            console.log("state", state);
            console.log("action", action);
            const { calledBy, value, operation } = action.payload;


            const operationIndex = state.data.findIndex(
                (opr, i) => {
                    if (state.data[i].uuid !== null) {
                        return opr.uuid === operation.uuid;
                    } else {
                        return state.data[i].operation_id === operation.operation_id;
                    }
                }
            );
            console.log("operationIndex", operationIndex);

            if (operationIndex !== -1) {
                if (calledBy == 'startDate') {
                    if (operation.uuid == state.selected_opr?.uuid) {
                        console.log("value", value);
                        state.selected_opr.operation_details.startDate = new Date(value).getTime();
                    }
                    state.data[operationIndex].operation_details.startDate = new Date(value).getTime();
                } else {
                    if (operation.uuid == state.selected_opr?.uuid) {
                        console.log("value", value);
                        state.selected_opr.operation_details.endDate = new Date(value).getTime();
                    }
                    state.data[operationIndex].operation_details.endDate = new Date(value).getTime();
                }
            }
        },
        handle_check_box(state, action) {
            const { value, operation, ind } = action.payload;

            state.data.forEach((item, i) => {
                console.log("item", item)
                console.log("operation", operation);
                if (ind === i) {
                    state.selected_opr = item;
                    // state.data[i].operation_details.selected = value;
                } else {
                    // state.data[i].operation_details.selected = false;
                }
            });
        },
        handle_job_card_in_opr(state, action) {
            console.log("action.payload", action.payload);

            for (let i = 0; i < state.data.length; i++) {
                let condition = null;

                // state.data[i].uuid here is product operation uuid if its not present it means 
                // oeprations is of global route
                if (state.data[i].uuid !== null) {
                    condition = state.data[i].uuid === state.selected_opr.uuid
                } else {
                    condition = state.data[i].operation_id === state.selected_opr.operation_id
                }
                console.log("condition", condition);
                console.log("state.selected_opr", state.selected_opr);

                if (condition) {
                    if (action.payload.action === "add") {
                        state.data[i].create_job_card = action.payload.data;
                    } else if (action.payload.action === "delete") {
                        delete state.data[i].create_job_card;
                    }
                }
            }

        },
        reset_operations(state, action) {
            console.log("action.payload", action.payload);

            if (action.payload
                && action.payload?.selected_route
                && action.payload?.selected_opr) {
                state.selected_route = action.payload.selected_route;
                state.selected_opr = action.payload.selected_opr;
            } else {
                state.selected_route = null;
                state.selected_opr = null;
                state.data = [];
            }
            state.status = "idle";
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoutesOpr.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchRoutesOpr.fulfilled, (state, action) => {
                state.status = "succeeded";
                console.log("action", action);
                state.data = action.payload.operation_list;
                state.selected_route = action.payload.selected_route;


                if (!state.selected_opr) {
                    state.selected_opr = action.payload.operation_list[0];
                }
            })
            .addCase(fetchRoutesOpr.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            });
    },
});


export const { set_global_route_opr, reset_operations, handle_date_change,
    handle_check_box, handle_job_card_in_opr } = routeOprSlice.actions;
export default routeOprSlice.reducer;
