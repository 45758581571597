import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import {
  calMaxPage,
  convertDateYYMMDD,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  getSalesOrder,
  create_WorkOrder,
  getInternalCompany,
  getCustomer,
  getLocation,
  getProdList,
  getProduct_Family,
  getSTockAvilablity,
  getSTockAvilablity_v3,
  update_WorkOrder,
  getProdConfig,
  getBOMByProduct,
  get_workorder_product_routes,
  getGlobalRouting,
} from "../../../api";
import DatePicker from "react-datepicker";
import UpdateImages from "./ImageGallary/UpdateImages";
import Product_Attributes from "./ProductAttributes/Product_Attributes";
import CreateBom from "../ProductionPlanning/CreateBom";
import * as name_const from "../../../common/Constants/constants";

const schema = yup
  .object()
  .shape({
    organization: yup
      .array()
      .min(1, "Please select organization !")
      .required("Please select organization!"),
    bom: yup
      .array()
      .min(1, "Please select bom !")
      .required("Please select bom !"),
    route: yup
      .array()
      .min(1, "Please select route !")
      .required("Please select route !"),
    startDate: yup
      .date()
      .typeError("Please select start date!")
      .required("Please select a start date!"),
    endDate: yup
      .date()
      .typeError("Please select end date!")
      .required("Please select a end date!"),
    quantity: yup
      .string()
      .transform((value, originalValue) => (originalValue == "" ? null : value))
      .matches(/^[0-9]+$/, "Enter valid quantity")
      .nullable(),
    jobFrom: yup
      .string()
      .transform((value, originalValue) => (originalValue == "" ? null : value))
      .matches(/^[0-9]+$/, "Enter valid job from")
      .nullable()
      .test(
        "jobFromLessThanjobTo",
        "Job from must be less than job to!",
        function (value) {
          const jobTo = this.resolve(yup.ref("jobTo"));
          const quantity = this.resolve(yup.ref("quantity"));
          if (!value) {
            return true; // If values is null or undefined, skip the test
          }

          if (jobTo !== null && quantity == 1) {
            return parseInt(value) == parseInt(jobTo);
          }

          if (jobTo !== null && jobTo > 1) {
            return parseInt(value) < parseInt(jobTo);
          }
          return true; // If either value is null, we do not perform the comparison
        }
      )
      .test(
        "quantityMatchesRange",
        "Quantity must match the range between jobFrom and jobTo!",
        function (value) {
          const jobTo = this.resolve(yup.ref("jobTo"));
          const quantity = this.resolve(yup.ref("quantity"));
          let range = jobTo - value + 1;
          if (!value || !jobTo || !quantity) {
            return true; // If values is null or undefined, skip the test
          }

          if (quantity > range || quantity < range) {
            return false;
          }

          return true; // If either value is null, we do not perform the comparison
        }
      ),
    jobTo: yup
      .string()
      .transform((value, originalValue) => (originalValue == "" ? null : value))
      .matches(/^[0-9]+$/, "Enter valid job to")
      .nullable()
      .test(
        "jobToGreaterThanjobFrom",
        "Job to must be greater than job from!",
        function (value) {
          const jobFrom = this.resolve(yup.ref("jobFrom"));
          if (!value) {
            return true; // If values is null or undefined, skip the test
          }
          if (jobFrom) {
            return parseInt(value) >= parseInt(jobFrom);
          }
          return true; // If either value is null, we do not perform the comparison
        }
      )
      .test(
        "quantityMatchesRange",
        "Quantity must match the range between jobFrom and jobTo!",
        function (value) {
          const jobFrom = this.resolve(yup.ref("jobFrom"));
          const quantity = this.resolve(yup.ref("quantity"));
          let range = value - jobFrom + 1;
          if (!value || !jobFrom || !quantity) {
            return true; // If values is null or undefined, skip the test
          }

          if (quantity > range || quantity < range) {
            return false;
          }

          return true; // If either value is null, we do not perform the comparison
        }
      ),
    items: yup.array().of(
      yup.object().shape({
        availqty: yup
          .string()
          .transform((value, newValue) => (newValue == "" ? null : value))
          .nullable(),
        quantity: yup
          .string()
          .transform((value, originalValue) =>
            originalValue == "" ? null : value
          )
          .matches(/^[0-9]+$/, "Enter valid quantity")
          .nullable()
          .test("checkQuantity", "Enter valid quantity", function (value) {
            const availqty = this.resolve(yup.ref("availqty"));
            return (
              value === null ||
              value === undefined ||
              parseInt(value) <= availqty
            );
          }),
      })
    ),
  })
  .required();

function Edit_Work_Order() {
  let navigate = useNavigate();
  let location = useLocation();
  // console.log("location", location);

  const [constLabel, setConstLabel] = useState({});
  const [start_date, setStart_Date] = useState(
    new Date(location.state.start_date)
  );
  const [end_date, setEnd_Date] = useState(new Date(location.state.end_date));
  const [salesOrders, setSalesOrders] = useState({ data: [], loading: false });
  const [stockList, setStockList] = useState({ data: [], loading: false });
  const [imageList, setImageList] = useState({ data: [], loading: false });

  const [orgList, setOrgList] = useState({ data: [], loading: false });
  const [customerList, setCustomerList] = useState({
    data: [],
    loading: false,
  });
  const [locList, setLocList] = useState({ data: [], loading: false });
  const [prodList, setProdList] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [bom_arr, set_bom_arr] = useState({ data: [], loading: false });
  const [productRoutes, setProductRoutes] = useState({
    data: [],
    loading: false,
  });

  const [lockBtn, setLockBtn] = useState(false);

  const [img_List, setImg_List] = useState({ data: [], loading: false });

  const [prodOprList, setProdOprList] = useState({ data: [], loading: false });
  const [statusChange, setStatusChange] = useState(false);

  const [workStatus, setWorkStatus] = useState([
    { id: 1, statusName: "Draft" },
    { id: 2, statusName: "Released" },
    { id: 3, statusName: "In Progress" },
    { id: 4, statusName: "Completed" },
    { id: 5, statusName: "Cancel" },
  ]);

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [modalData, setModalData] = useState({ state: false, data: null, ind: null });

  const {
    handleSubmit,
    register,
    control,
    formState,
    watch,
    reset,
    setError,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const selSalesOrder = watch("sales_order");
  const selProduct = watch("product");
  const selectedBOM = watch("bom");
  const selLoc = watch("location");
  const selStatus = watch("status");
  const route = watch("route");

  const stock_items = watch("items");

  useEffect(() => {
    // console.log("location.state", location.state);
    setConstLabel(getLabels("Production", "Work Order"));
    fetchSalesOrder();
    // fetchOrganizations();
    fetchCustomers();
    // fetchLocations();
    fetchProdList();
    // fetchProdFamily();
    setValue("startDate", start_date);
    setValue("endDate", end_date);
    setValue("quantity", location.state?.quantity);

    // console.log("location.state", location.state);

    if (location.state.bom_details) {
      location.state.bom_details.bom_rid = `bom_${location.state.bom_details.id}`;
      setValue("bom", [location.state.bom_details]);
    } else {
      setValue("bom", []);
    }

    if (location.state.route_uuid !== null && location.state.routeData !== null) {
      setValue("route", [
        {
          routes1: location.state.routeData?.routesId,
          routesname: location.state.routeData?.routesType,
          routes_id: location.state.routeData?.uuid,
        },
      ]);
    } else {
      // setValue("route", []);
      setValue("route", [
        {
          routes1: location.state?.globalrouteData?.id,
          routesname: location.state?.globalrouteData?.routeName,
          routes_id: location.state?.globalrouteData?.uuid,
        },
      ]);
    }

    setValue("jobFrom", location.state?.serial_number_start);
    setValue("jobTo", location.state?.serial_number_end);
    setValue("status", [{ statusName: location.state?.work_order_status }]);

    setValue("remark", location.state?.remark);
    setValue("part_no", location.state?.part_no);
    setImg_List({ data: location.state?.work_order_files });

    const result_list = [];

    location.state?.stock_details?.forEach((item) => {
      let newProduct = {
        product: [
          {
            uuid: item.productid_id,
            productName: item.product_name,
            productId: item.productId,
            materialType: item.materialType,
          },
        ],
        store: item?.store_id ? [
          {
            uuid: item.store_id,
            name: item.store_name,
          },
        ] : [],
        barDetails:
          item.materialType === "BAR"
            ? [
              {
                uuid: item.bar_uuid,
                bar_no: item.bar_no,
                avail_qty: item.quantity,
                // total_length: item.length,
                total_length: item.total_length,
                tooltran_cutid: item.tooltran_cutid,
                avail_length: item.length,
                bar_weight_per_meter: item.bar_weight,
                diameter: item.diameter ?? null,
                action: item?.action ?? null,
                material_code: item?.material_code ?? null,
                oneCutPieceLen: item?.oneCutPieceLen ?? null,
                prodQty: item?.prodQty ?? null,
                product_familyId: item?.product_familyId ?? null,
                work_order_qty: item?.work_order_qty ?? null,
                conversionLenPrice: item?.conversionLenPrice,
                inventoryCost: item?.inventoryCost,
                consume_price: item?.consume_price
              },
            ]
            : [],
        unit: item.unit,
        materialCode: item.materialCode,
        length: item.length, // Assuming total_length is length in input
        availqty: item.materialType === "BAR" ? item.prodQty : item.quantity, // Assuming availqty and quantity are the same in input
        quantity: item.quantity.toString(),
        oneCutPieceLen: item.oneCutPieceLen, // Assuming oneCutPieceLen is not present in input, set accordingly
        prodQty: item.prodQty,
        inventoryCost: item?.inventoryCost !== undefined && item?.inventoryCost !== null ?
          item?.inventoryCost.toString() : "0",
        perProductPrice: item?.perProductPrice !== undefined && item?.perProductPrice !== null ?
          item?.perProductPrice.toString() : "0",
        consume_price: item?.consume_price !== undefined && item?.consume_price !== null ?
          item?.consume_price.toString() : "0",
        allocate:
          item.materialType === "BAR"
            ? Number(item.quantity) * Number(item.oneCutPieceLen)
            : 0, // Assuming prodQty is not present in input, set accordingly
      };
      result_list.push(newProduct);
    });

    // console.log("result_list", result_list);
    setValue("items", result_list, { shouldValidate: true });
  }, []);

  useEffect(() => {
    // console.log("selSalesOrder", selSalesOrder);
    if (selSalesOrder !== undefined && selSalesOrder.length > 0) {
      setValue("organization", [selSalesOrder?.[0]?.org_id]);
      setValue("customer", [selSalesOrder?.[0]?.invoice_to]);
      // setValue("product", [
      //   selSalesOrder?.[0]?.so_order_lines?.[selSalesOrder?.[0]?.so_order_lines.length - 1]?.product_id,
      // ]);
      setValue("product", [location.state.product_info]);

      fetch_bom_list(location.state.product_info?.uuid);
      // fetch_bom_list(selSalesOrder?.[0]?.so_order_lines?.[selSalesOrder?.[0]?.so_order_lines.length - 1]?.product_id?.uuid);
      // fetch_wo_product_routes(
      //   selSalesOrder?.[0]?.so_order_lines?.[selSalesOrder?.[0]?.so_order_lines.length - 1]?.product_id?.uuid
      // );

      fetch_wo_product_routes(location.state.product_info?.uuid);

      if (location.state?.route_uuid !== null && location.state.routeData !== null) {
        fetchProdOprRouting(
          1,
          10,
          // selSalesOrder?.[0]?.so_order_lines?.[selSalesOrder?.[0]?.so_order_lines.length - 1]?.product_id?.uuid,
          location.state.product_info?.uuid,
          location.state?.routeData?.uuid
        );
      } else {
        setProdOprList({ ...prodOprList, data: location.state?.globalrouteData?.routeData, loading: false });
      }

      setValue("prod_family", [
        // selSalesOrder?.[0]?.so_order_lines?.[selSalesOrder?.[0]?.so_order_lines.length - 1]?.product_id?.prod_family_id,
        location.state.product_info?.prodfamily1
      ]);
      setValue(
        "part_no",
        // selSalesOrder?.[0]?.so_order_lines?.[selSalesOrder?.[0]?.so_order_lines.length - 1]?.product_id?.partNo
        location.state.product_info?.partNo
      );
      // fetchOrganizations(selSalesOrder?.[0]?.org_id?.uuid);
    } else {
      fetchOrganizations(null);
      // setValue("organization", []);
      setValue("customer", []);
      setValue("product", []);
      setValue("prod_family", []);
      setValue("location", []);
      setValue("part_no", "");
    }
  }, [selSalesOrder]);

  useEffect(() => {
    if (
      location?.state?.work_order_status !== "Draft" &&
      location?.state?.work_order_status !== "Open"
    ) {
      // console.log("location.state if", location?.state?.work_order_status);
      // setStockList({
      //   data:location.state.stock_details ,
      //   loading: false,
      // });
      // if (selProduct && selProduct.length > 0 && selLoc && selLoc.length > 0 && selectedBOM && selectedBOM.length > 0) {
      //   fetchStockAvailablity(selProduct?.[0]?.uuid, selLoc?.[0]?.uuid, selectedBOM[0].uuid);
      // } else {
      //   setValue("items", []);
      // }
    } else {
      // console.log("location.state if", location?.state?.work_order_status);
      // console.log("selProduct", selProduct);
      if (
        selProduct &&
        selProduct.length > 0 &&
        selLoc &&
        selLoc.length > 0 &&
        selectedBOM &&
        selectedBOM.length > 0
      ) {
        fetchStockAvailablity(
          selProduct?.[0]?.uuid,
          selLoc?.[0]?.uuid,
          selectedBOM[0].uuid
        );
      } else {
        setValue("items", []);
      }
    }

    // if (selProduct && selProduct.length > 0 && route && route.length > 0) {
    //   // console.log("route", route);
    //   fetchProdOprRouting(1, 10, selProduct?.[0]?.uuid, route[0].routes_id);
    // }

    // if (selProduct && selProduct.length > 0) {
    //   fetch_bom_list(selProduct?.[0]?.uuid);
    //   fetch_wo_product_routes(selProduct?.[0]?.uuid);
    // }
  }, [selProduct, selLoc, selectedBOM]);

  useEffect(() => {
    var stockItems = [];

    // console.log("stockList", stockList);

    if (stockList && stockList.data && stockList.data.length > 0) {
      // const stockItems = stockList.data.map((item, ind) => {
      let arr = [];
      for (let i = 0; i < stockList.data.length; i++) {
        let item = stockList.data[i];
        // console.log("item", item);
        if (item.barDetails && item.barDetails.length > 0) {
          let a = item.barDetails.map((ele, ind) => {
            const { barDetails, ...rest } = item;
            //  console.log("barDetails", barDetails);
            let obj = {
              ...ele,
              ...rest,
            };

            obj.work_order_qty = barDetails[ind].work_order_qty || 0;
            // console.log("obj 1", obj);

            return obj;
          });
          // console.log("a", a);
          arr = [...arr, ...a];
        } else {
          arr.push(item);
        }
      }

      arr.sort((a, b) => a.id - b.id);
      // arr.sort((a, b) => {
      //   let aParts = a.bar_no.split(/[-_]+/).map(part => part.padStart(4, '0'));
      //   let bParts = b.bar_no.split(/[-_]+/).map(part => part.padStart(4, '0'));
      //   return aParts.join().localeCompare(bParts.join());
      // });
      // console.log("arr 1", arr);

      for (let i = 0; i < arr.length; i++) {
        let obj = {
          product: [
            {
              uuid: arr?.[i]?.product_id,
              productName: arr?.[i]?.product_name,
              productId: arr?.[i]?.productId,
              materialType: arr?.[i]?.materialType,
            },
          ],
          store: [
            {
              uuid: arr?.[i]?.store_id,
              name: arr?.[i]?.store_name,
            },
          ],
          barDetails:
            arr?.[i]?.materialType !== null &&
              arr?.[i]?.materialType == "BAR" &&
              arr?.[i]?.bar_no !== undefined
              ? [
                {
                  uuid: arr?.[i]?.baruuid,
                  bar_no: arr?.[i]?.bar_no,
                  avail_qty: arr?.[i]?.avail_qty,
                  total_length: arr?.[i]?.total_length,
                  tooltran_cutid: arr?.[i]?.tooltran_cutid,
                  avail_length: arr?.[i]?.avail_length,
                  // avail_length: arr?.[i]?.conversionLen,
                  bar_weight_per_meter: arr?.[i]?.bar_weight_per_meter,
                  diameter: arr?.[i]?.diameter,
                  action: arr?.[i]?.action,
                  material_code: arr?.[i]?.material_code,
                  oneCutPieceLen: arr?.[i]?.oneCutPieceLen,
                  prodQty: arr?.[i]?.prodQty,
                  product_familyId: arr?.[i]?.product_familyId,
                  work_order_qty: arr?.[i]?.work_order_qty,
                  conversionLenPrice: arr?.[i]?.conversionLenPrice,
                  inventoryCost: arr?.[i]?.inventoryCost,
                  consume_price: arr?.[i]?.consume_price
                },
              ]
              : [],
          unit: arr?.[i]?.unit,
          materialCode: arr?.[i]?.materialCode,
          // length: arr?.[i]?.materialType == "BAR" ? arr?.[i]?.total_length : "",
          length: arr?.[i]?.materialType == "BAR" ? arr?.[i]?.avail_length : "",
          availqty:
            arr?.[i]?.materialType !== null &&
              arr?.[i]?.materialType == "BAR" &&
              arr?.[i]?.bar_no !== undefined
              ? arr?.[i]?.avail_qty
              : arr?.[i]?.availqty,
          quantity: arr?.[i]?.work_order_qty
            ? (arr?.[i]?.work_order_qty).toString()
            : "0",
          oneCutPieceLen: arr?.[i]?.oneCutPieceLen,
          prodQty: arr?.[i]?.prodQty,
          inventoryCost: arr?.[i]?.inventoryCost !== undefined && arr?.[i]?.inventoryCost !== null ?
            arr?.[i]?.inventoryCost.toString() : "0",
          perProductPrice: arr?.[i]?.perProductPrice !== undefined && arr?.[i]?.perProductPrice !== null ?
            arr?.[i]?.perProductPrice.toString() : "0",
          consume_price: arr?.[i]?.consume_price !== undefined && arr?.[i]?.consume_price !== null ?
            arr?.[i]?.consume_price.toString() : "0",
        };


        // console.log("stock_items", stock_items);


        // if(arr?.[i].total_length > 0 && arr?.[i].avail_length > 0 ){
        //   obj.length = arr?.[i].total_length;
        // } else {
        //   obj.length = 0;
        // }
        // console.log("obj 2", obj);

        if (obj.barDetails.length == 0) {
          obj.length = "0";
          obj.availqty = (arr[i]?.work_order_qty ?? 0) + obj.availqty;
          obj.allocate = "0";
          obj.materialCode = arr?.[i]?.materialCode;
        } else {
          // console.log("arr[i]?.barDetails.",arr[i])
          // result = len / av_qty;
          obj.length =
            ((arr[i]?.work_order_qty ?? 0) + obj.availqty) *
            arr?.[i]?.oneCutPieceLen;
          obj.availqty = (arr[i]?.work_order_qty ?? 0) + arr[i]?.avail_qty;
          // obj.allocate = arr?.[i]?.avail_qty > 0 ? ((arr?.[i]?.total_length / obj.availqty) * arr[i]?.work_order_qty).toString() : (arr?.[i]?.total_length).toString();
          // obj.allocate = arr?.[i]?.avail_qty > 0 ? (arr?.[i]?.oneCutPieceLen * arr[i]?.work_order_qty).toString() : (arr?.[i]?.total_length).toString();


          obj.allocate = (
            arr?.[i]?.work_order_qty * Number(arr?.[i]?.oneCutPieceLen)
          ).toString();
          obj.materialCode = arr?.[i]?.material_code;
        }



        // console.log("obj 3", obj);

        if (location.state?.stock_details) {
          // console.log(
          //   "location.state?.stock_details",
          //   location.state?.stock_details
          // );

          // console.log("arr?.[i]", arr?.[i]);
          // console.log("location.state?.stock_details", location.state?.stock_details);



          // const resultBar = location.state?.stock_details.find(
          //   (val) =>
          //     val.bar_no == arr?.[i]?.bar_no &&
          //     val.materialCode == arr?.[i]?.material_code
          // );
            // console.log("resultBar", resultBar);

          //   obj.quantity = resultBar ? resultBar.quantity.toString() : location.state?.stock_details?.[i]?.quantity.toString();
          //   if (statusChange == true) {
          //     obj.quantity = stock_items[i].quantity;
          //   }

          let resultBar;

          if (arr?.[i]?.bar_no != null) {
            // Check for bar_no if it's not undefined or null
            resultBar = location.state?.stock_details.find(
              (val) =>
                val.bar_no == arr?.[i]?.bar_no &&
                val.materialCode == arr?.[i]?.material_code
            );
            // console.log("resultBar if", resultBar);
          } else {
            // Fallback to materialType and materialCode if bar_no is null or undefined
            resultBar = location.state?.stock_details.find(
              (val) =>
                val.materialType == arr?.[i]?.materialType &&
                val.materialCode == arr?.[i]?.materialCode && val.productid_id == arr?.[i]?.product_id
            );
            // console.log("resultBar else", resultBar);
          }

          // Assign the quantity
          obj.quantity = resultBar
            ? resultBar.quantity.toString()
            : "0";

          // Overwrite quantity if statusChange is true
          if (statusChange) {
            obj.quantity = stock_items[i].quantity;
          }




          obj.avail_length = resultBar?.avail_length;
          // console.log("obj.quantity", obj.quantity);

          if (resultBar) {
            obj.allocate =
              arr?.[i]?.materialType == "BAR" && obj.quantity !== ""
                ? Number(obj.quantity) *
                Number(resultBar.oneCutPieceLen)
                : 0;
          } else {
            obj.allocate =
              arr?.[i]?.materialType == "BAR" && obj.quantity !== ""
                ? Number(obj.quantity) *
                0
                : 0;
          }

          // console.log("obj.allocate", obj.allocate);
          // allocate: item.materialType === 'BAR' ? (Number(item.quantity) * Number(item.oneCutPieceLen)) : 0
        }

        stockItems.push(obj);
      }

      // console.log("stockItems", stockItems);
      setValue("items", stockItems, { shouldValidate: true });
    }
  }, [stockList]);

  const currentStatus = location.state?.work_order_status || "Draft";

  const currentStatusIndex = workStatus.findIndex(
    (status) => status.statusName === currentStatus
  );

  const filteredStatusOptions = workStatus.slice(currentStatusIndex);

  function fetch_wo_product_routes(product_id) {
    setProductRoutes({ ...productRoutes, data: [], loading: true });

    get_workorder_product_routes({ product_id: product_id }).then(
      (res) => {
        setProductRoutes({ ...productRoutes, data: res.data.data, loading: false });
      },
      (err) => {
        setProductRoutes({ ...productRoutes, data: [], loading: false });
        // console.log("err", err);
      }
    );
  }

  function fetchSalesOrder() {
    setSalesOrders({ ...salesOrders, data: [], loading: true });

    getSalesOrder({ so_status: JSON.stringify([name_const.BOMCREATED, name_const.IN_PROGRESS, name_const.APPROVED]) }).then(
      // getSalesOrder().then(
      (res) => {
        if (
          location.state?.so_id !== undefined &&
          location.state?.so_id !== null
        ) {
          const result = res.data.data.filter(
            (item) => item.uuid === location.state.so_id
          );
          setValue("sales_order", result);
          // console.log("result", result);
        }
        setSalesOrders({ ...salesOrders, data: res.data.data, loading: false });
      },
      (err) => {
        setSalesOrders({ ...salesOrders, data: [], loading: false });
      }
    );
  }

  function fetchOrganizations(id) {
    setOrgList({ ...orgList, data: [], loading: true });
    const payload = id
      ? { uuid: selSalesOrder?.[0]?.org_id?.uuid, with_location: 1 }
      : { with_location: 1 };

    getInternalCompany(payload).then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.uuid === location.state?.org_id
        );
        setValue("organization", result);
        setValue("location", [location?.state?.location_details]);
        setOrgList({ ...orgList, data: res.data.data, loading: false });

        if (id && res.data.data.length == 1) {
          setLocList({
            data: res.data.data?.[0]?.location_list,
            loading: false,
          });
        } else {
          setLocList({
            data: result?.[0]?.location_list,
            loading: false,
          });
        }
      },
      (err) => {
        setOrgList({ ...orgList, data: [], loading: false });
      }
    );
  }

  function fetchCustomers() {
    setCustomerList({ ...customerList, loading: true, data: [] });
    getCustomer({ partyType: "Customer" }).then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.organization !== null
        );

        if (location.state?.party_id) {
          const setParty = result.filter(
            (item) => item.uuid === location.state?.party_id
          );
          // console.log("setParty", setParty);
          setValue("customer", setParty);
        } else {
          // setValue("customer", []);
        }

        setCustomerList({
          ...customerList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        //
        setCustomerList({ ...customerList, loading: false, data: [] });
      }
    );
  }

  function fetchLocations() {
    setLocList({ ...locList, data: [], loading: true });

    getLocation().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.locationname != null
        );

        setLocList({ ...locList, data: result, loading: false });
      },
      (err) => {
        setLocList({ ...locList, data: [], loading: false });
      }
    );
  }

  function fetchProdList() {
    setProdList({ ...prodList, data: [], loading: true });

    getProdList().then(
      (res) => {
        let arr = res.data.data.filter(
          (item) => item.productName !== null || item.productName !== undefined
        );

        if (location.state?.so_id == null) {
          let setProd = arr.filter(
            (item) => item.uuid === location.state?.product_info?.uuid
          );
          setValue("product", [location.state?.product_info]);
          // console.log("setProd", setProd);
          fetch_bom_list(location.state?.product_info?.uuid);
          fetch_wo_product_routes(location.state?.product_info?.uuid);  // just commented 08-01-2025 6:10


          {/* added for global route 08/10/24 */ }
          if(location.state.route_uuid !== null && location.state.routeData !== null) {
            fetchProdOprRouting(1, 10, location.state?.product_info?.uuid, location.state?.routeData?.uuid);
          } else {
            setProdOprList({ ...prodOprList, data: location.state?.globalrouteData?.routeData, loading: false });
          }
          {/* added for global route 08/10/24 */ }

          setValue("prod_family", [location.state?.product_info?.prodfamily1]);
          setValue("part_no", location.state?.product_info?.partNo);
        }

        setProdList({ ...prodList, data: arr, loading: false });
      },
      (err) => {
        setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  function fetchProdFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        let arr = res.data.data.filter(
          (item) => item.familyname !== null || item.familyname !== undefined
        );
        setProdFamily({ ...prodFamily, data: arr, loading: false });
      },
      (err) => {
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchStockAvailablity(prod_uuid, loc_uuid, bom_uuid) {
    setStockList({ ...stockList, data: [], loading: true });
    const payload = {
      product_uuid: prod_uuid,
      location_uuid: loc_uuid,
      bom_uuid: bom_uuid,
      // work_order_uuid: location.state.uuid
    };

    const currentStatus = location.state?.work_order_status || "Draft";
    const currentStatusIndex = workStatus.findIndex(
      (status) => status.statusName === currentStatus
    );

    if (currentStatusIndex > 1) {
      payload.work_order_uuid = location.state.uuid;
    }

    // getSTockAvilablity(payload).then(
    getSTockAvilablity_v3(payload).then(
      (res) => {
        // console.log("getSTockAvilablity_v3 res", res);
        if (res.data.data.length == 0) {
          setValue("bom", res.data.data);
        }
        setStockList({
          ...stockList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        // console.log("err", err);
        if (
          err.response?.status !== 404 &&
          location.state?.stock_details?.length == 0
        ) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
        }
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
        setValue("items", []);
        setStockList({
          ...stockList,
          data: [],
          loading: false,
        });
      }
    );
  }

  function fetchProdOprRouting(pg, epp, prod_uuid, route_uuid) {
    // console.log("route_uuid", route_uuid);
    setProdOprList({ ...prodOprList, data: [], loading: true });
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      product_id: prod_uuid,
      routes_uuid: route_uuid,
    };
    getProdConfig(payload).then(
      (res) => {
        setProdOprList({
          ...prodOprList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
      },
      (err) => {
        setProdOprList({ ...prodOprList, data: [], loading: false });
      }
    );
  }

  // for temporary use of below function
  // function generateRandomString() {
  //   const year = new Date().getFullYear();
  //   const randomDigits = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit number

  //   return `WO-${year}-${randomDigits}`;
  // }

  function allocateValue(para1, index) {
    // console.log("para1", para1);
    // console.log("index", index);
    let result = "";
    let len = getValues(`items[${index}].length`);
    let av_qty = getValues(`items[${index}].availqty`);

    if (av_qty == 0) {
      setValue(`items[${index}].quantity`, "0");
    }

    if (av_qty < para1) {
      // toast.error("Please enter valid quantity", {
      //   autoClose: 2000,
      // });
      // setValue(`items[${index}].quantity`, "0");
      setValue(`items[${index}].allocate`, "0");
    }
    // console.log("len", len);
    // console.log("av_qty", av_qty);

    if (
      len !== undefined &&
      len !== "" &&
      av_qty !== undefined &&
      av_qty !== "" &&
      av_qty !== 0 &&
      av_qty > 0
    ) {
      result = len / av_qty;
      setValue(`items[${index}].allocate`, (result * para1).toString());
    } else {
      result = 0;
      setValue(`items[${index}].allocate`, (result * para1).toString());
    }
  }

  function barCalc(wpm, bl) {
    if (wpm !== undefined && wpm !== 0 && bl !== undefined && bl !== "") {
      return ((wpm / 1000) * parseInt(bl)).toFixed(2);
    } else {
      return "0";
    }
  }

  /**
   * Returns the UUID of a line item based on the given product_id.
   *
   * @param {Array} lineItemArr - Array of line items, each containing a `product_id` and `uuid`.
   * @param {string} product_id - The product ID to search for.
   * @returns {string|null} - The UUID of the matching line item, or null if not found.
   */
  function getLineItemUUID(lineItemArr, productUUID) {
    if (!Array.isArray(lineItemArr) || !productUUID) {
      return null;
    }
  
    const lineItem = lineItemArr.find((item) => item.product_id?.uuid === productUUID);
    return lineItem ? lineItem.uuid : null;
  }

  const onSubmit = (data) => {
    // console.log("data", data);
    // let totalQuantity = 0;
    setLockBtn(true);
    if (data !== "") {
      let payload = [];
      for (let i = 0; i < data.items.length; i++) {
        // if (data.items?.[i]?.quantity <= 0) {
        //   // Show toast error message
        //   toast.error("Allocated quantity must be greater than 0.",{
        //     autoClose: 2000
        //   });
        //   // Exit the loop and do not create the record
        //   return;
        // }

        // if (data.items?.[i]?.quantity !== null && data.items?.[i]?.quantity !== "") {
        //   // Show toast error message
        //   totalQuantity += parseFloat(data.items[i].quantity);
        //   if (totalQuantity > data.quantity) {
        //     // Set the error message or flag

        //     setError(`items[${i}].quantity`, {
        //       type: "custom",
        //       message: "Allocate qty sum not exceeds than quantity !"
        //     })
        //     setLockBtn(false);
        //     // Exit the loop and do not create the record
        //     return;
        //   }
        // }

        const lineItems = {
          productid_id: data.items?.[i]?.product?.[0]?.uuid,
          productId: data.items?.[i]?.product?.[0]?.productId,
          product_name: data.items?.[i]?.product?.[0]?.productName,
          length:
            data.items?.[i]?.allocate !== null &&
              data.items?.[i]?.allocate !== ""
              ? (data.items?.[i]?.allocate).toString()
              : "",
          quantity: data.items?.[i]?.quantity,
          materialCode: data.items?.[i]?.materialCode,
          materialType: data.items?.[i]?.product?.[0]?.materialType,
          unit: data.items?.[i]?.unit,

          store_id: data.items?.[i]?.store?.[0]?.uuid,
          store_name: data.items?.[i]?.store?.[0]?.name
        };

        if (
          data.items?.[i]?.product?.[0]?.materialType === null ||
          data.items?.[i]?.product?.[0]?.materialType !== "BAR"
        ) {
          delete lineItems.bar_uuid;
          delete lineItems.bar_weight;
          delete lineItems.bar_no;
          delete lineItems.tooltran_cutid;

          lineItems.inventoryCost = (data.items?.[i]?.inventoryCost).toString();
          lineItems.consume_price = Number(data.items?.[i]?.perProductPrice) * Number(data.items?.[i]?.quantity);
          // delete lineItems.materialType;
          // delete lineItems.materialCode;
          // delete lineItems.unit;
        } else {
          lineItems.bar_uuid =
            data.items?.[i]?.barDetails.length > 0
              ? data.items?.[i]?.barDetails?.[0]?.uuid
              : null;
          lineItems.bar_no =
            data.items?.[i]?.barDetails.length > 0
              ? data.items?.[i]?.barDetails?.[0]?.bar_no
              : null;
          lineItems.bar_weight =
            (data.items?.[i]?.allocate !== undefined ||
              data.items?.[i]?.allocate !== 0) &&
              (data.items?.[i]?.barDetails?.[0]?.bar_weight_per_meter !==
                undefined ||
                data.items?.[i]?.barDetails?.[0]?.bar_weight_per_meter !== 0)
              ? barCalc(
                data.items?.[i]?.barDetails?.[0]?.bar_weight_per_meter,
                data.items?.[i]?.allocate
              )
              : null;
          lineItems.tooltran_cutid =
            data.items?.[i]?.barDetails.length > 0
              ? data.items?.[i]?.barDetails?.[0]?.tooltran_cutid
              : null;
          lineItems.action =
            data.items?.[i]?.barDetails.length > 0
              ? data.items?.[i]?.barDetails?.[0]?.action
              : null;
          lineItems.material_code =
            data.items?.[i]?.barDetails.length > 0
              ? data.items?.[i]?.barDetails?.[0]?.material_code
              : null;
          lineItems.oneCutPieceLen =
            data.items?.[i]?.barDetails.length > 0
              ? data.items?.[i]?.barDetails?.[0]?.oneCutPieceLen
              : null;
          lineItems.prodQty =
            data.items?.[i]?.barDetails.length > 0
              ? data.items?.[i]?.barDetails?.[0]?.prodQty
              : null;
          lineItems.product_familyId =
            data.items?.[i]?.barDetails.length > 0
              ? data.items?.[i]?.barDetails?.[0]?.product_familyId
              : null;
          lineItems.work_order_qty =
            data.items?.[i]?.barDetails.length > 0
              ? (data.items?.[i]?.barDetails?.[0]?.work_order_qty ?? null) !==
                null
                ? (data.items?.[i]?.barDetails?.[0]?.work_order_qty).toString()
                : null
              : null;
          // lineItems.materialType = data.items?.[i]?.product?.[0]?.materialType;
          // lineItems.materialCode = data.items?.[i]?.materialCode;
          // lineItems.unit = data.items?.[i]?.unit;
          lineItems.total_length =
            data.items?.[i]?.barDetails.length > 0
              ? data.items?.[i]?.barDetails?.[0]?.total_length?.toString()
              : "0";

          lineItems.conversionLenPrice = data.items?.[i]?.barDetails?.length > 0 &&
            data.items?.[i]?.barDetails?.[0]?.conversionLenPrice !== null ?
            (data.items?.[i]?.barDetails?.[0]?.conversionLenPrice).toString() : "0";

          lineItems.inventoryCost = data.items?.[i]?.barDetails?.length > 0 &&
            data.items?.[i]?.barDetails?.[0]?.inventoryCost !== null ?
            (data.items?.[i]?.barDetails?.[0]?.inventoryCost).toString() : "0";

          lineItems.consume_price =
            data.items?.[i]?.barDetails?.length > 0 && data.items?.[i]?.barDetails?.[0]?.conversionLenPrice ?
              ((data.items?.[i]?.barDetails?.[0]?.conversionLenPrice / Number(data.items?.[i]?.barDetails?.[0]?.prodQty))
                * data.items?.[i]?.quantity).toString() : "0";
        }

        if (
          data.items?.[i]?.availqty !== undefined &&
          data.items?.[i]?.availqty !== null &&
          data.items?.[i]?.availqty > 0 &&
          data.items?.[i]?.quantity !== undefined &&
          data.items?.[i]?.quantity !== null &&
          data.items?.[i]?.quantity > 0
        ) {
          payload.push(lineItems);
        }
      }

      const reqPayload = {
        uuid: location.state?.uuid,
        org_id: data.organization?.[0]?.uuid,
        bom_uuid: data.bom?.[0]?.uuid,
        // route_uuid: data.route[0]?.routes_id,
        location_id: data.location?.[0]?.uuid,
        party_id: data.customer?.[0]?.uuid,
        product_id: data.product?.[0]?.uuid,
        product_name: data.product?.[0]?.productName,
        productId: data.product?.[0]?.productId,
        // part_no: data.part_no,
        quantity: parseInt(data.quantity),
        unit: data.product?.[0]?.uomproduct?.uom || data.product?.[0]?.unit_id?.uom ,
        remark: data.remark,
        work_order_status: data.status?.[0]?.statusName,
        serial_number_start: parseInt(data.jobFrom),
        serial_number_end: parseInt(data.jobTo),
        start_date: convertDateYYMMDD(data.startDate, "-"),
        end_date: convertDateYYMMDD(data.endDate, "-"),
        // oem: data.part_no,
        bom_list: payload,
        word_order_files: imageList.data,
      };

      if (data?.part_no !== null && data?.part_no !== "") {
        reqPayload.part_no = data?.part_no;
      }

      if (data.route?.length > 0 && data.route?.[0]?.routesname !== "Global") {
        reqPayload.route_uuid = data.route?.[0]?.routes_id;
      } else {
        reqPayload.global_route_uuid = data.route?.[0]?.routes_id;
      }

      if (selSalesOrder !== undefined && selSalesOrder?.length > 0) {
        reqPayload.so_id = selSalesOrder?.[0]?.uuid;
        // reqPayload.so_line_id = selSalesOrder?.[0]?.so_order_lines?.[0]?.uuid;
        reqPayload.so_line_id = getLineItemUUID(selSalesOrder?.[0]?.so_order_lines, reqPayload.product_id); 
        reqPayload.amount = selSalesOrder?.[0]?.final_total;
        reqPayload.consume_price = Number(selSalesOrder?.[0]?.final_total) * Number(data.quantity);
      } else {
        reqPayload.amount = Number(data.product?.[0]?.salesPrice);
        reqPayload.consume_price = Number(data.product?.[0]?.salesPrice) * Number(data.quantity);
      }

      // console.log("reqPayload", reqPayload);
      update_WorkOrder(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Work order updated successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/work-order");
            }, 2200);
            setLockBtn(false);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }

          if (err.response.status === 404) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
          setLockBtn(false);
        }
      );
    } else {
      errors.showMessages();
    }
  };

  const handleFromDateChange = (date) => {
    if (end_date && date > end_date) {
      return;
    }
    setStart_Date(date);
  };

  const handleToDateChange = (date) => {
    if (start_date && date < start_date) {
      return;
    }
    setEnd_Date(date);
  };

  function fetch_bom_list(product_uuid, set_latest_bom) {
    set_bom_arr({ ...bom_arr, loading: true, data: [] });

    getBOMByProduct({ product_id: product_uuid }).then(
      (res) => {
        // console.log("res", res);
        // const new_list = res.data.data.map((val) => {
        //   val.bom_rid = `bom_${val.id}`;
        //   return val;
        // });
        set_bom_arr({ ...bom_arr, loading: false, data: res.data.data });

        if (set_latest_bom) {
          setValue('bom', [res.data.data[0]])
        }
      },
      (err) => {
        toast.error(err.response.data.message, { autoClose: 2000});
        set_bom_arr({ ...bom_arr, loading: false, data: [] });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {modalData.state &&
        <CreateBom
          modalData={modalData}
          setModalData={setModalData}
          preparingBomList={null}
          consolidate={null}
          fetch_bom_list={fetch_bom_list}
        />
      }

      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                navigate("/pcms/work-order");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_update_work_order
                ? constLabel.lbl_update_work_order
                : "Update Work Order"}
            </p>
          </div>
          <div className="card-body">
            <div className="row pb-2">
              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_sales_order
                      ? constLabel.lbl_sales_order
                      : "Sales Order"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <Controller
                    name="sales_order"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="so_id"
                        multiple={false}
                        options={
                          salesOrders.data !== undefined ? salesOrders.data : []
                        }
                        isLoading={salesOrders.loading}
                        placeholder="Select sales order"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled
                      // isInvalid={errors.sales_order ? true : false}
                      />
                    )}
                  />
                  {!salesOrders.loading && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                        {errors.sales_order?.message}
                    </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_organization_name
                      ? constLabel.lbl_organization_name
                      : "Organization"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="organization"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="comName"
                        multiple={false}
                        options={orgList.data !== undefined ? orgList.data : []}
                        isLoading={orgList.loading}
                        placeholder="Select organization"
                        onChange={(selected) => {
                          onChange(selected);
                          //    console.log("location",selected)
                          if (selected && selected.length > 0) {
                            setLocList({
                              data: selected?.[0]?.location_list,
                              loading: false,
                            });
                          } else {
                            setLocList({
                              data: [],
                              loading: false,
                            });
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        // disabled={
                        //   selSalesOrder && selSalesOrder.length > 0
                        //     ? true
                        //     : false
                        // }
                        disabled
                        isInvalid={errors.organization ? true : false}
                      />
                    )}
                  />
                  {!orgList.loading && !errors.organization && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.organization?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_customer_name
                      ? constLabel.lbl_customer_name
                      : "Customer Name"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <Controller
                    name="customer"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="organization"
                        multiple={false}
                        options={
                          customerList.data !== undefined
                            ? customerList.data
                            : []
                        }
                        isLoading={customerList.loading}
                        placeholder="Select customer"
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled
                        // disabled={
                        //   selSalesOrder && selSalesOrder.length > 0
                        //     ? true
                        //     : false
                        // }
                        selected={value}
                      // isInvalid={errors.customer ? true : false}
                      />
                    )}
                  />
                  {!customerList.loading && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                        {errors.customer?.message}
                    </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_location_name
                      ? constLabel.lbl_location_name
                      : "Location Name"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <Controller
                    name="location"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="locationname"
                        multiple={false}
                        options={locList.data}
                        isLoading={locList.loading}
                        placeholder="Select location"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={
                          selStatus?.[0]?.statusName !== "Draft" &&
                            selStatus?.[0]?.statusName ===
                            location.state?.work_order_status
                            ? true
                            : false
                        }
                      // isInvalid={errors.locationname ? true : false}
                      />
                    )}
                  />
                  {!locList.loading && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                        {errors.locationname?.message}
                    </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_product_name
                      ? constLabel.lbl_product_name
                      : "Product Name"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <Controller
                    name="product"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="productName"
                        multiple={false}
                        options={
                          prodList.data !== undefined ? prodList.data : []
                        }
                        isLoading={prodList.loading}
                        placeholder="Select product"
                        onChange={(selected) => {
                          onChange(selected);
                          if (selected && selected.length > 0) {
                            // console.log("selected", selected);
                            fetch_bom_list(selected?.[0]?.uuid);
                            fetch_wo_product_routes(selected?.[0]?.uuid); 

                            if (route.length > 0 && route?.[0]?.routesname !== "Global") {
                              fetchProdOprRouting(
                                page,
                                entriesPerPage,
                                selected?.[0]?.uuid,
                                route[0].routes_id
                              );
                            } else {

                            }

                            setValue("prod_family", [
                              selected?.[0]?.prod_family_id,
                            ]);
                            setValue("part_no", selected?.[0]?.partNo);
                          } else {
                            setProdOprList({ data: [] });
                            setStockList({ data: [] });
                            set_bom_arr({ ...bom_arr, data: [] });
                            setValue("bom", []);
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        disabled={
                          (selSalesOrder && selSalesOrder.length > 0) ||
                            (selStatus?.[0]?.statusName !== "Draft" &&
                              selStatus?.[0]?.statusName ===
                              location.state?.work_order_status)
                            ? true
                            : false
                              ? true
                              : false
                        }
                      // isInvalid={errors.product ? true : false}
                      />
                    )}
                  />
                  {!prodList.loading && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                        {errors.product?.message}
                    </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_product_family
                      ? constLabel.lbl_product_family
                      : "Product Family"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <Controller
                    name="prod_family"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="familyname"
                        multiple={false}
                        options={
                          prodFamily.data !== undefined ? prodFamily.data : []
                        }
                        isLoading={prodFamily.loading}
                        placeholder="Select product family"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={
                          (selSalesOrder && selSalesOrder.length > 0) ||
                            (selProduct && selProduct.length > 0)
                            ? true
                            : false
                        }
                      // isInvalid={errors.prod_family ? true : false}
                      />
                    )}
                  />
                  {!prodFamily.loading && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                        {errors.prod_family?.message}
                    </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_part_no
                      ? constLabel.lbl_part_no
                      : "Part No"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <input
                    type="text"
                    className="form-control mt-2"
                    id="part_no"
                    name="part_no"
                    placeholder="Enter part no"
                    disabled={
                      (selSalesOrder && selSalesOrder.length > 0) ||
                        (selProduct && selProduct.length > 0)
                        ? true
                        : false
                    }
                    {...register("part_no")}
                  />
                  {/* <span className="text-danger err-msg">
                        {errors.part_no?.message}
                    </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_customer_name
                      ? constLabel.lbl_customer_name
                      : "Route"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="route"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="routesname"
                        multiple={false}
                        options={productRoutes.data}
                        isLoading={productRoutes.loading}
                        placeholder="Select route"
                        onChange={(selected) => {
                          onChange(selected);

                          if (selected.length > 0 && selProduct.length > 0) {
                            fetchProdOprRouting(
                              page,
                              entriesPerPage,
                              selProduct?.[0]?.uuid,
                              selected[0].routes_id
                            );
                          } 
                          // else {
                          //   setProdOprList({
                          //     ...prodOprList,
                          //     loading: false,
                          //     data: [],
                          //   });
                          // }
                        }}
                        onBlur={onBlur}
                        disabled={
                          (selProduct && selProduct?.length == 0) ||
                          selStatus?.[0]?.statusName !== "Draft" ||
                          location.state?.global_route_uuid !== null
                        }
                        selected={value}
                        isInvalid={errors.route ? true : false}
                      />
                    )}
                  />
                  {!prodFamily.loading && !errors.route && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.route?.message}
                  </span>
                </div>
              </div>

              {/* <div className="col-12 col-md-3 mt-3">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_product_name
                      ? constLabel.lbl_product_name
                      : "Select BOM"}
                    <span className="text-danger"> *</span>
                  </label>


                  <Controller
                    name="bom"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="bomId"
                        multiple={false}
                        options={
                          bom_arr.data
                        }
                        isLoading={bom_arr.loading}
                        placeholder="Select bom"
                        onChange={(selected) => {
                          console.log("selected", selected);
                          setValue("bom", selected, { shouldValidate: true });
                          if (selected.length > 0) {
                            fetchStockAvailablity(selProduct?.[0]?.uuid, selLoc?.[0]?.uuid, selected[0].uuid);
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.bom ? true : false}
                        disabled={selProduct?.length == 0}
                      />
                    )}
                  />
                  <span className="text-danger err-msg">
                    {errors.bom?.message}
                  </span>
                </div>
              </div> */}

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_quantity
                      ? constLabel.lbl_quantity
                      : "Quantity"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <input
                    type="text"
                    className="form-control mt-2"
                    id="quantity"
                    name="quantity"
                    placeholder="Enter quantity"
                    {...register("quantity")}
                    disabled={
                      selStatus?.[0]?.statusName !== "Draft" &&
                        selStatus?.[0]?.statusName ===
                        location.state?.work_order_status
                        ? true
                        : false
                    }
                  />
                  {/* <span className="text-danger err-msg">
                        {errors.quantity?.message}
                    </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_start_date
                      ? constLabel.lbl_start_date
                      : "Start Date"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.startDate
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select start date"
                        disabled
                        selected={start_date}
                        onChange={(date) => {
                          field.onChange(date);
                          handleFromDateChange(date);
                        }}
                        minDate={start_date}
                        dateFormat="dd/MM/yyyy"
                        autoComplete="false"
                      />
                    )}
                  />
                  {errors.startDate && (
                    <span className="text-danger err-msg">
                      {errors.startDate.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_end_date
                      ? constLabel.lbl_end_date
                      : "End Date"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.endDate
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select end date"
                        selected={field.value}
                        onChange={(date) => {
                          field.onChange(date);
                          handleToDateChange(date);
                        }}
                        minDate={start_date}
                        dateFormat="dd/MM/yyyy"
                        autoComplete="false"
                        disabled={
                          (selProduct && selProduct?.length == 0) ||
                          selStatus?.[0]?.statusName !== "Draft"
                        }
                      />
                    )}
                  />
                  {errors.endDate && (
                    <span className="text-danger err-msg">
                      {errors.endDate.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_job_from
                      ? constLabel.lbl_job_from
                      : "Job From"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <input
                    className={
                      errors.jobFrom
                        ? "form-control mt-2 is-invalid"
                        : "form-control mt-2"
                    }
                    id="jobFrom"
                    name="jobFrom"
                    type="text"
                    placeholder="Enter job from"
                    {...register("jobFrom")}
                    disabled={
                      (selProduct && selProduct?.length == 0) ||
                      selStatus?.[0]?.statusName !== "Draft"
                    }
                  />
                  {errors.jobFrom && (
                    <span className="text-danger err-msg">
                      {errors.jobFrom.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_job_to ? constLabel.lbl_job_to : "Job To"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <input
                    className={
                      errors.jobTo
                        ? "form-control mt-2 is-invalid"
                        : "form-control mt-2"
                    }
                    id="jobTo"
                    name="jobTo"
                    type="text"
                    placeholder="Enter job to"
                    {...register("jobTo")}
                    disabled={
                      (selProduct && selProduct?.length == 0) ||
                      selStatus?.[0]?.statusName !== "Draft"
                    }
                  />
                  {errors.jobTo && (
                    <span className="text-danger err-msg">
                      {errors.jobTo.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  {/* <Controller
                    name="status"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="statusName"
                        multiple={false}
                        // size="sm"
                        options={workStatus}
                        placeholder="Select status"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      // isInvalid={errors.status ? true : false}
                      />
                    )}
                  /> */}
                  <Controller
                    name="status"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="statusName"
                        multiple={false}
                        options={workStatus}
                        placeholder="Select status"
                        onChange={(selected) => {
                          onChange(selected);
                          // console.log("selected", selected);
                          // console.log("selProduct", selProduct);
                          // console.log("selectedBOM", selectedBOM);
                          // console.log("selLoc", selLoc);
                          if (selected.length > 0) {
                            setStatusChange(true);

                            if (selProduct && selProduct.length > 0 &&
                              selLoc && selLoc.length > 0 &&
                              selectedBOM && selectedBOM.length > 0 &&
                              selected[0].statusName == "Released") {
                              fetchStockAvailablity(selProduct?.[0]?.uuid, selLoc?.[0]?.uuid, selectedBOM[0].uuid);
                            }
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        disabled={location.state?.work_order_status == "Released" ? true : false}
                      // isInvalid={errors.status ? true : false}
                      filterBy={() => true}
                      />
                    )}

                  />
                  {workStatus.length > 0 && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                        {errors.status?.message}
                    </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_remark ? constLabel.lbl_remark : "Remark"}
                  </label>

                  <input
                    type="text"
                    className="form-control mt-2"
                    id="remark"
                    name="remark"
                    placeholder="Enter remark"
                    {...register("remark")}
                  />
                </div>
              </div>
            </div>

            {/* <hr className="my-2" />
            <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
              {constLabel?.lbl_work_order_image
                ? constLabel.lbl_work_order_image
                : "Work Order Image"}
            </p>
            <hr className="my-2" />

            <UpdateImages
              imageList={imageList}
              setImageList={setImageList}
              constLabel={constLabel}
              img_List={img_List}
            /> */}

            <hr className="my-2" />
            <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
              {constLabel?.lbl_bom_detail
                ? constLabel.lbl_bom_detail
                : "BOM Detail"}
            </p>
            <hr className="my-2" />

            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_name
                    ? constLabel.lbl_product_name
                    : "Select BOM"}
                  <span className="text-danger"> *</span>
                </label>

                <Controller
                  name="bom"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      className="mt-2"
                      labelKey="bomId"
                      multiple={false}
                      options={bom_arr.data}
                      isLoading={bom_arr.loading}
                      placeholder="Select bom"
                      onChange={(selected) => {
                        // console.log("selected", selected);
                        setValue("bom", selected, { shouldValidate: true });
                        // if (selected.length > 0) {
                        //   fetchStockAvailablity(selProduct?.[0]?.uuid, selLoc?.[0]?.uuid, selected[0].uuid);
                        // }
                      }}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.bom ? true : false}
                      disabled={
                        selProduct?.length == 0 ||
                        selStatus?.[0]?.statusName !== "Draft"
                      }
                    />
                  )}
                />


                {selProduct && selProduct.length > 0
                  && selStatus?.[0]?.statusName == "Draft" &&
                  <div>
                    <p
                      id="create_workorder_link"
                      onClick={() => {
                        setModalData({
                          ...modalData,
                          state: true,
                          data: selProduct[0],
                          ind: 0
                        })
                      }}>
                      <u>Create BOM</u></p>
                  </div>
                }
                <span className="text-danger err-msg">
                  {errors.bom?.message}
                </span>
              </div>
            </div>

            <div className="row my-4">
              {prodList.loading || stockList.loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : stock_items?.length > 0 ? (
                <>
                  <div
                    className=""
                    style={{ overflowY: "scroll", maxHeight: "60vh" }}
                  >
                    <table className="table mt-2 table-responsive table-bordered table-sm">
                      <thead className="table-grey roboto-bold">
                        <tr className="">
                          <th scope="col" width={"23%"}>
                            {constLabel?.lbl_material
                              ? constLabel.lbl_material
                              : "Material"}
                          </th>
                          <th scope="col" width={"12%"}>
                            {constLabel?.lbl_store
                              ? constLabel.lbl_store
                              : "Store"}
                          </th>
                          <th scope="col">
                            {constLabel?.lbl_bar_code
                              ? constLabel.lbl_bar_code
                              : "Bar Code"}
                          </th>
                          <th scope="col">
                            {constLabel?.lbl_batch_code
                              ? constLabel.lbl_batch_code
                              : "Batch Code"}
                          </th>
                          <th scope="col">
                            {constLabel?.lbl_available_length
                              ? constLabel.lbl_available_length
                              : "Available Length"}
                          </th>
                          {location.state !== null &&
                            location.state?.work_order_status !== "Released" ? (
                            <th scope="col">
                              {constLabel?.lbl_available_qty
                                ? constLabel.lbl_available_qty
                                : "Available Qty"}
                            </th>
                          ) : null}
                          <th scope="col">
                            {constLabel?.lbl_allocate_qty
                              ? constLabel.lbl_allocate_qty
                              : "Allocate Qty"}
                          </th>
                          <th scope="col">
                            {constLabel?.lbl_unit
                              ? constLabel.lbl_unit
                              : "Unit"}
                          </th>
                          <th scope="col">
                            {constLabel?.lbl_allocate_length
                              ? constLabel.lbl_allocate_length
                              : "Allocated length"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(({ id }, index) => (
                          <tr key={id} className="f-18">
                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].product`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="productName"
                                    size="sm"
                                    multiple={false}
                                    // options={prodType.data}
                                    // isLoading={prodType.loading}
                                    // placeholder="Select product name"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    disabled
                                    isInvalid={
                                      errors.items?.[index]?.product
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />

                              {errors.items?.[index]?.product && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.product.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].store`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="name"
                                    size="sm"
                                    multiple={false}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    disabled
                                    isInvalid={
                                      errors.items?.[index]?.store
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />

                              {errors.items?.[index]?.store && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.store.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top", width: "15%" }}>
                              <Controller
                                name={`items[${index}].barDetails`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="bar_no"
                                    size="sm"
                                    multiple={false}
                                    // options={prod_List.data}
                                    // isLoading={prod_List.loading}
                                    // placeholder="Select bar_no"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    disabled
                                    isInvalid={
                                      errors.items?.[index]?.barDetails
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />

                              {errors.items?.[index]?.barDetails && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.barDetails.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top", width: "15%" }}>
                              <input
                                className={
                                  errors.items?.[index]?.materialCode
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].materialCode`}
                                {...register(`items[${index}].materialCode`)}
                                disabled
                              />

                              {errors.items?.[index]?.materialCode && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.materialCode.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.length
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].length`}
                                {...register(`items[${index}].length`)}
                                disabled
                              />

                              {errors.items?.[index]?.length && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.length.message}
                                </span>
                              )}
                            </td>

                            {location.state !== null &&
                              location.state?.work_order_status !== "Released" ? (
                              <td style={{ verticalAlign: "top" }}>
                                <input
                                  className={
                                    errors.items?.[index]?.availqty
                                      ? "form-control form-control-sm is-invalid"
                                      : "form-control form-control-sm"
                                  }
                                  type="text"
                                  name={`items[${index}].availqty`}
                                  {...register(`items[${index}].availqty`)}
                                  disabled
                                />

                                {/* {errors.items?.[index]?.availqty && (
                                  <span className="text-danger err-msg">
                                    {errors.items?.[index]?.availqty.message}
                                  </span>
                                )} */}
                              </td>
                            ) : null}

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.quantity
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].quantity`}
                                {...register(`items[${index}].quantity`, {
                                  onChange: (e) =>
                                    allocateValue(e.target.value, index),
                                })}
                                disabled={
                                  selStatus?.[0]?.statusName !== "Draft" &&
                                    selStatus?.[0]?.statusName ===
                                    location.state?.work_order_status
                                    ? true
                                    : false
                                }
                              />

                              {errors.items?.[index]?.quantity && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.quantity.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={"form-control form-control-sm"}
                                type="text"
                                name={`items[${index}].unit`}
                                {...register(`items[${index}].unit`)}
                                disabled
                              />
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.allocate
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                name={`items[${index}].allocate`}
                                {...register(`items[${index}].allocate`)}
                                disabled
                              />

                              {/* {errors.items?.[index]?.allocate && (
                                  <span className="text-danger err-msg">
                                    {errors.items?.[index]?.allocate.message}
                                  </span>
                                )} */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-1"
                  role="alert"
                >
                  {"No Records Found!"}
                </div>
              )}
            </div>

            {/* Product Operation Routing Details */}
            <hr className="my-2" />
            <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
              {constLabel?.lbl_rounting ? constLabel.lbl_routing : "Routing"}
            </p>
            <hr className="my-2" />

            {route && route?.[0]?.routesname !== "Global" ?
              <div className="row">
                {prodOprList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : prodOprList.data.length > 0 ? (
                  <div className="col-12 col-md-12">
                    <table className="table table-responsive table-bordered table-sm">
                      <thead className="table-grey roboto-bold">
                        <tr>
                          <th width={"10%"}>
                            {constLabel?.lbl_sequence
                              ? constLabel?.lbl_sequence
                              : "Sequence"}
                          </th>
                          <th>
                            {constLabel?.lbl_operation
                              ? constLabel?.lbl_operation
                              : "Operation"}
                          </th>
                          <th>
                            {constLabel?.lbl_cycle_time
                              ? constLabel?.lbl_cycle_time
                              : "Cycle Time"}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {prodOprList.data?.map((val, ind) => (
                          <tr key={ind}>
                            <td>{val.sequenceNo}</td>
                            <td>{val.operationname}</td>
                            <td>
                              {val.machine?.map((v, i) => (
                                <span key={i}>
                                  {v.serialNo}
                                  {" - "}
                                  {v.cycle_time}m{","}
                                </span>
                              ))}
                            </td>
                            <td>
                              <Product_Attributes data={val} />
                              <button
                                onClick={() => navigate("/pcms/create-job-card",
                                  {
                                    state: {
                                      work_order_details: location.state,
                                      operation: val
                                    }
                                  })}
                                className="btn btn-outline-secondary btn-sm me-3"
                                disabled={location.state == undefined || location.state?.work_order_status !== "Released"}>
                                {constLabel?.lbl_create_job_order
                                  ? constLabel?.lbl_job_work_order
                                  : "Create Job Order"}
                              </button>

                              <button
                                // onClick={() => navigate("/pcms/job-card", {
                                //   state: {
                                //     work_order_no: location.state.work_order_no,
                                //     operation: {
                                //       operationID: val.operationid,
                                //       uuid: val.operation_id,
                                //       operationName: val.operationname
                                //     }
                                //   }
                                // })}
                                onClick={() => navigate(`/pcms/job-card-board`, {
                                  state: location.state,
                                })}
                                disabled={location.state == undefined || location.state?.work_order_status !== "Released"}
                                className="btn btn-outline-secondary btn-sm">
                                {constLabel?.lbl_view_job_order
                                  ? constLabel?.lbl_view_job_order
                                  : "View Job Order"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="mt-4 d-flex justify-content-between">
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${prodOprList.data.length +
                        entriesPerPage * page -
                        entriesPerPage
                        } of ${prodOprList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchProdOprRouting(
                              page - 1,
                              entriesPerPage,
                              selProduct?.[0]?.uuid,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > prodOprList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchProdOprRouting(
                              page + 1,
                              entriesPerPage,
                              selProduct?.[0]?.uuid,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center px-2"
                    role="alert"
                  >
                    {"Product operation routing not found !"}
                  </div>
                )}
              </div>
              :
              <div className="row">
                {prodOprList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : prodOprList.data.length > 0 ? (
                  <div className="col-12 col-md-12">
                    <table className="table table-responsive table-bordered table-sm">
                      <thead className="table-grey roboto-bold">
                        <tr>
                          <th width={"10%"}>
                            {constLabel?.lbl_sequence
                              ? constLabel?.lbl_sequence
                              : "Sequence"}
                          </th>
                          <th>
                            {constLabel?.lbl_operation
                              ? constLabel?.lbl_operation
                              : "Operation"}
                          </th>
                          <th>
                            {constLabel?.lbl_cycle_time
                              ? constLabel?.lbl_cycle_time
                              : "Cycle Time"}
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {prodOprList.data?.map((val, ind) => (
                          <tr key={ind}>
                            <td>{val.sequence_no}</td>
                            <td>{val.operationname}</td>
                            <td>
                              <span>{val.serialNo}{" - "}{val.cycle_time}m{","}</span>
                            </td>
                            <td>
                              <Product_Attributes prod_id={selProduct?.[0]} />
                              <button
                                onClick={() => navigate("/pcms/create-job-card",
                                  {
                                    state: {
                                      work_order_details: location.state,
                                      operation: val
                                    }
                                  })}
                                className="btn btn-outline-secondary btn-sm me-3"
                                disabled={location.state == undefined || location.state?.work_order_status !== "Released"}>
                                {constLabel?.lbl_create_job_order
                                  ? constLabel?.lbl_job_work_order
                                  : "Create Job Order"}
                              </button>

                              <button
                                // onClick={() => navigate("/pcms/job-card", {
                                //   state: {
                                //     work_order_no: location.state.work_order_no,
                                //     operation: {
                                //       operationID: val.operationid,
                                //       uuid: val.operation_id,
                                //       operationName: val.operationname
                                //     }
                                //   }
                                // })}
                                onClick={() => navigate(`/pcms/job-card-board`, {
                                  state: location.state,
                                })}
                                disabled={location.state == undefined || location.state?.work_order_status !== "Released"}
                                className="btn btn-outline-secondary btn-sm">
                                {constLabel?.lbl_view_job_order
                                  ? constLabel?.lbl_view_job_order
                                  : "View Job Order"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center px-2"
                    role="alert"
                  >
                    {"Product operation routing not found !"}
                  </div>
                )}
              </div>
            }

          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
              disabled={lockBtn}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default Edit_Work_Order;
