// components/CalenderViewV3.js
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
// import ProductSelector from "./ProductSelector";
import EmployeeSlider from "./EmployeeSlider";
import { fetchEmployees, createJobCard, deleteJobCard, reset_state }
    from "../../../../slices/production_planning/employee_availability_slice";
import { fetchRoutes } from "../../../../slices/production_planning/routeSlice"
import {
    fetchRoutesOpr, set_global_route_opr,
    reset_operations, handle_date_change, handle_check_box
} from "../../../../slices/production_planning/route_operation_slice";
import { selectProduct, handle_date, save_routes_operations } from "../../../../slices/production_planning/production_plan_slice2";
import DatePicker from "react-datepicker";
import {
    getWeekRange, create_job_obj, assignDatesToOperationsReverse,
    convertDateYYMMDD, adjustDateTime, call_sweet_alert, getTrueDaysDates
} from "../../../../common/Functions/CommonFunctions";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { Calendar, momentLocalizer, Navigate, Views } from 'react-big-calendar'
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import moment from "moment"
import * as dates from 'date-arithmetic';
import { RESOURCE_COLORS } from "../../../../common/Constants/constants";
import JobCardModalV3 from "./JobCardModalV3";
import SweetAlert from "sweetalert2";

const localizer = momentLocalizer(moment)


const CalenderViewV3 = (props) => {

    const MyWeek = ({
        date,
        localizer,
        max = localizer.endOf(new Date(), 'day'),
        min = localizer.startOf(new Date(), 'day'),
        // scrollToTime = localizer.startOf(new Date(), 'day'),
        // scrollToTime = new Date(1970, 1, 1, 10, 0, 0),
        resources, // Add resources prop
        events,
        resourceIdAccessor,
        resourceTitleAccessor,
        startAccessor,
        endAccessor,
        ...props
    }) => {
        // Get the custom range for the 3-day week view
        // const currRange = useMemo(() => MyWeek.range(date, { localizer }), [date, localizer]);
        const currRange = MyWeek.range(date, { localizer });
        console.log("props", props);
        console.log("resources", resources);
        // console.log("scrollToTime", scrollToTime);
        console.log("currRange", currRange);
        console.log("events", events);
        console.log("max", max);
        console.log("min", min);


        return (
            <TimeGrid
                date={date}
                eventOffset={15}
                localizer={localizer}
                max={max}
                min={min}
                range={currRange}
                // scrollToTime={scrollToTime}
                resources={resources} // Pass resources to TimeGrid
                events={events}
                startAccessor={startAccessor} // Convert start to Date
                endAccessor={endAccessor}// Pass resources to TimeGrid
                resourceIdAccessor={resourceIdAccessor} // Resource ID accessor
                resourceTitleAccessor={resourceTitleAccessor}
                {...props}
            />
        );
    };

    MyWeek.range = (date, { localizer }) => {
        const start = date;
        const end = dates.add(start, 2, 'day'); // Add 2 days to the start date to get a 3-day range

        let current = start;
        const range = [];

        while (localizer.lte(current, end, 'day')) {
            range.push(current);
            current = localizer.add(current, 1, 'day');
        }

        return range;
    };

    // Navigate function for custom view to move back and forth between 3-day views
    MyWeek.navigate = (date, action, { localizer }) => {
        switch (action) {
            case Navigate.PREVIOUS:
                return localizer.add(date, -3, 'day'); // Move back 3 days
            case Navigate.NEXT:
                return localizer.add(date, 3, 'day'); // Move forward 3 days
            default:
                return date;
        }
    };

    // Title for custom view
    MyWeek.title = (date, { localizer }) => {
        const start = date; // Start date of the custom week
        const end = dates.add(start, 2, 'day'); // End date (start + 2 days for 3-day week)

        const formatDate = (date) => {
            console.log("date", date);
            return new Date(date).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })
        };

        return `${formatDate(start)} - ${formatDate(end)}`;
    };


    const { nextStep, prevStep } = props;
    const dispatch = useDispatch();

    const [constLabel, setConstLabel] = useState({});
    const { list: routes, status: routeListStatus } = useSelector((state) => state.routes);
    const { emp_list, status: emp_list_status } = useSelector((state) => state.employees);
    const { base_data, selectedProduct, status } = useSelector((state) => state.products);
    const { data: operation_list, status: operation_list_status,
        selected_route, selected_opr } = useSelector((state) => state.operation_list);
    const StepOneSavedData = useSelector((state) => state.steps.StepOne);
    const [disableDates, setDisableDates] = useState([]);
    const { data: productionplanDetails, } = useSelector((state) => state.prod_plan_details);

    const [currentDate, setCurrentDate] = useState(new Date());
    const [visible, setVisible] = useState({ state: false, data: null, displayData: null });
    const [view, setView] = useState("customThreeDays");
    const [colorMapper, setColorMapper] = useState({});

    useEffect(() => {
        console.log("base_data", base_data);
        console.log("selectedProduct", selectedProduct);
        console.log("StepOneSavedData", StepOneSavedData);
        handleProductChange(base_data[0]);


        dispatch(handle_date({
            start_date: new Date(StepOneSavedData.formData.plan_date),
            end_date: new Date(StepOneSavedData.formData.delivery_date),
            big_calender_date: new Date(),
            view: "month"
        }));

        setDisableDates(disableDatesFn);
    }, []);

    useEffect(() => {
        console.log("productionplanDetails", productionplanDetails);
        console.log("routes", routes);

        // if (!productionplanDetails.workorder) {
        if (routes?.route_list?.length > 0) {
            const payload = {
                product_id: selectedProduct.uuid,
                routes_uuid: routes.route_list[0].routes_id
            }
            dispatch(fetchRoutesOpr({
                payload,
                startdate: new Date(StepOneSavedData.formData.plan_date),
                enddate: new Date(StepOneSavedData.formData.delivery_date),
                selected_route: routes.route_list[0],
                StepOneSavedData
            }));
        } else if (routes?.global_route_list?.length > 0) {

            const temp_operation_list = [];
            routes.global_route_list[0].routeData.forEach(element => {
                const item = {
                    uuid: null,
                    sequence_no: element.sequence_no,
                    operationname: element.operationname,
                    operation_id: element.operation_id,
                    machine: [{
                        uuid: null,
                        cycle_time: element.cycle_time,
                        serialNo: element.serialNo,
                        machine_id_id: element.machine_id_id,
                        operation_id_id: element.operation_id,
                        name: element.machine_name
                    }],
                    operation_details: con_glo_oper_json_to_oper_json(element)
                }
                temp_operation_list.push(item);
            });

            const prod_index = base_data.findIndex((p) =>
                p.uuid === selectedProduct.uuid);

            let reversedOpr = [];
            try {
                reversedOpr = temp_operation_list.sort((a, b) => parseInt(b.sequence_no) - parseInt(a.sequence_no));
            } catch (err) {
                console.log('err', err);
                reversedOpr = temp_operation_list;
            }

            // here we are adding start date end date dynamically through fn
            const newOperations = assignDatesToOperationsReverse(
                // [...temp_operation_list].reverse(),
                [...reversedOpr],
                new Date(StepOneSavedData.formData.plan_date),
                new Date(StepOneSavedData.formData.delivery_date),
                base_data[prod_index]?.selected_route?.route_operation_list,
                StepOneSavedData
            );
            console.log("newOperations", newOperations);
            console.log("routes.global_route_list", routes.global_route_list);

            dispatch(set_global_route_opr(
                {
                    operlist: newOperations,
                    selected_global_route: routes.global_route_list[0]
                }));

        }
        // }
    }, [routes]);

    useEffect(() => {
        console.log("selected_route", selected_route);
    }, [selected_route])


    const disableDatesFn = useMemo(() => {
        return getTrueDaysDates(StepOneSavedData);
    }, [StepOneSavedData]);

    function con_glo_oper_json_to_oper_json(data) {
        return {
            uuid: data.operation_id,
            operationName: data.operationname,
            operationCategoryId: data.operationCategoryId,
            operation_category_id: data.operation_category_id,
            oprationCategoryName: data.oprationCategoryName,
            operation_cost: data.operation_cost,
            man_hour_cost: data.man_hour_cost,
            machine_data: [{
                machine: [{
                    machineName: data.machine_name,
                    uuid: data.machine_id_id,
                    serialNo: data.serialNo,
                }],
                cycleTime: data.cycle_time,
                settingTime: data.setting_time,
            }]
        }
    }

    useEffect(() => {
        console.log("selected_opr", selected_opr);
        fetch_emp_job_cards();
    }, [selected_opr]);


    function fetch_emp_job_cards() {
        if (selected_opr) {
            const oper_category_id_list = [];
            if (!selected_opr.operation_details.operation_category_id) {

                call_sweet_alert("Cannot Proceed",
                    "Cannot fetch employee availability, operation doesnt have operation category");
                // toast.error("Cannot fetch employee availability, operation dooesnt have operation category", {
                //     autoClose: 5000,
                // });
                return
            } else {
                oper_category_id_list.push(selected_opr.operation_details.operation_category_id);
            }

            const weeklyConfig = {
                monday: StepOneSavedData.formData.monday,
                tuesday: StepOneSavedData.formData.tuesday,
                wednesday: StepOneSavedData.formData.wednesday,
                thursday: StepOneSavedData.formData.thursday,
                friday: StepOneSavedData.formData.friday,
                saturday: StepOneSavedData.formData.saturday,
                sunday: StepOneSavedData.formData.sunday
            }
            const params = {
                startDate: convertDateYYMMDD(StepOneSavedData.formData.plan_date, "-"),
                endDate: convertDateYYMMDD(StepOneSavedData.formData.delivery_date, "-"),
                oper_category: JSON.stringify(oper_category_id_list),
                weeklyConfig: JSON.stringify(weeklyConfig)
            }
            console.log("params", params);
            if (selectedProduct.prod_family_id && selectedProduct.prod_family_id?.uuid) {
                params.product_family_id = selectedProduct.prod_family_id.uuid;
            }
            dispatch(fetchEmployees(params));
        }
    }

    function generateColorMap(employees) {
        const colorMap = {};

        employees.forEach((employee, index) => {
            colorMap[employee.uuid] = RESOURCE_COLORS[index % RESOURCE_COLORS.length];
        });

        return colorMap;
    }

    const events = useMemo(() => {
        const allEvents = [];
        setColorMapper(generateColorMap(emp_list));

        console.log("operation_list", operation_list);
        console.log("base_data", base_data);
        console.log("emp_list", emp_list);

        emp_list.forEach((employee) => {
            employee.jobcard_list.forEach((jobCard) => {
                jobCard = JSON.parse(JSON.stringify(jobCard));
                jobCard.start_time = adjustDateTime(jobCard.start_time);
                jobCard.end_time = adjustDateTime(jobCard.end_time);

                allEvents.push({
                    ...jobCard,
                    resourceId: employee.uuid,
                })
            })
        });

        if (operation_list && operation_list.length > 0) {

            operation_list.forEach((opr) => {
                if (opr.create_job_card) {
                    const job_card_to_append_in_calender = create_job_obj(opr.create_job_card);
                    allEvents.push(job_card_to_append_in_calender);
                }
            })
        }

        // this code is required to display all local job cards saved on front end when 
        // a product is selected
        console.log("selectedProduct", selectedProduct);
        if (base_data && base_data?.length > 0 && selectedProduct) {
            console.log("one");
            for (const b_item of base_data) {
                console.log("two");
                const dont_match_curr_prod =
                    b_item.uuid !== selectedProduct.uuid &&
                    // b_item.so_uuid !== selectedProduct.so_uuid &&
                    b_item.so_item_uuid !== selectedProduct.so_item_uuid;

                console.log("dont_match_curr_prod", dont_match_curr_prod);

                if (dont_match_curr_prod && b_item.selected_route
                    && b_item.selected_route?.route_operation_list) {
                    console.log("three");

                    b_item.selected_route?.route_operation_list?.forEach((opr) => {
                        console.log("opr.create_job_card", opr.create_job_card);
                        if (opr.create_job_card) {
                            const job_card_to_append_in_calender = create_job_obj(opr.create_job_card);
                            allEvents.push(job_card_to_append_in_calender);
                        }
                    })
                }
            }
        }

        console.log("allEvents", allEvents);
        return [...allEvents];
    }, [emp_list, operation_list, selectedProduct, base_data])



    const handleProductChange = (product) => {
        console.log("Selected Product:", product);
        console.log("selectedProduct", selectedProduct);
        console.log("selected_route", selected_route);
        console.log("operation_list", operation_list);

        if (selectedProduct && selected_route && operation_list) {
            dispatch(save_routes_operations({
                data: selectedProduct,
                route: selected_route,
                operations: operation_list
            }))
        }

        dispatch(fetchRoutes({ product_id: product.uuid }));


        const prod_index = base_data.findIndex((p) =>
            p.uuid === product.uuid);

        dispatch(selectProduct(base_data[prod_index]));

        console.log("base_data", base_data);
        console.log("prod_index", prod_index);
        if (base_data[prod_index].selected_route) {

            // checking if product has global or normal route 
            if (base_data[prod_index].selected_route.routes_id) {
                const payload = {
                    product_id: base_data[prod_index].uuid,
                    routes_uuid: base_data[prod_index].selected_route.routes_id
                }
                console.log("payload", payload)
                dispatch(fetchRoutesOpr({
                    payload,
                    startdate: new Date(StepOneSavedData.formData.plan_date),
                    enddate: new Date(StepOneSavedData.formData.delivery_date),
                    selected_route: base_data[prod_index].selected_route,
                    already_saved_opr_list: base_data[prod_index].selected_route.route_operation_list,
                    StepOneSavedData
                }));
            } else {
                console.log("set opr list and global route");
                dispatch(set_global_route_opr({
                    operlist: JSON.parse(JSON.stringify(base_data[prod_index].selected_route.route_operation_list)),
                    selected_global_route: base_data[prod_index].selected_route
                }))
            }

            console.log("reseting operation 1");
            dispatch(reset_operations({
                selected_route: base_data[prod_index].selected_route,
                selected_opr: base_data[prod_index].selected_route.route_operation_list[0]
            }));

        } else {
            console.log("reseting operation 2");
            dispatch(reset_operations());
        }

        dispatch(reset_state());
    };


    const handleJobCardCreate = (employeeId, start, end) => {
        const newJobCard = {};
        dispatch(createJobCard(newJobCard));
    };

    const handleJobCardDelete = (jobCardId) => {
        dispatch(deleteJobCard(jobCardId));
    };


    function validateProdHasOneJC(submit_base_data) {
        console.log("submit_base_data", submit_base_data);
        for (const prod of submit_base_data) {
            if (!prod.selected_route) {
                call_sweet_alert("Cannot Proceed", "At least one job card is required for each sales order product.");
                return
            }

            if (!Array.isArray(prod.selected_route.route_operation_list) ||
                prod.selected_route.route_operation_list.length === 0) {
                call_sweet_alert("Cannot Proceed", "Every selected route must have a operation list.");
                return
            }

            const isJobCardPresent = prod.selected_route.route_operation_list.find(
                (val) => val.create_job_card !== null && val.create_job_card !== undefined);

            if (!isJobCardPresent) {
                call_sweet_alert("Cannot Proceed", "Each product should have atleast one job card.");
                return
            }

        }

        return true; // If validation passes
    }


    function submitStep() {
        console.log("base_data", base_data);
        const submit_base_data = JSON.parse(JSON.stringify(base_data));
        if (selectedProduct && selected_route && operation_list) {
            dispatch(save_routes_operations({
                data: selectedProduct,
                route: selected_route,
                operations: operation_list
            }))

            for (let i = 0; i < submit_base_data.length; i++) {
                if (submit_base_data[i].uuid == selectedProduct.uuid) {
                    submit_base_data[i].selected_route = JSON.parse(JSON.stringify(selected_route));
                    submit_base_data[i].selected_route.route_operation_list = operation_list
                }
            }
        }

        const isValidated = validateProdHasOneJC(submit_base_data);
        if (isValidated) {
            nextStep();
            console.log("go to next step");
        }
    }

    if (status === "loading") return <p>Loading products...</p>;
    if (status === "failed") return <p>Failed to load products.</p>;


    return (
        <React.Fragment>

            <div className="calender_layoutv3 mt-2 stepFour">
                <div className='row' id='row1'>
                    <div className='col-12 mb-4'>
                        <div className="product_container">

                            {base_data && base_data.length > 0 &&
                                base_data.map((val, ind) => (
                                    <div
                                        className={val.uuid == selectedProduct?.uuid ?
                                            "card item active" : "card item shadow-sm"}


                                        key={ind}
                                        onClick={async () => {
                                            if (emp_list_status !== 'loading'
                                                && status !== "loading"
                                                && operation_list_status !== "loading"
                                                && routeListStatus !== 'loading') {
                                                handleProductChange(val);
                                            } else {
                                                toast.warning("Please wait & try again", {
                                                    autoClose: 3000,
                                                });
                                            }
                                        }}>
                                        <p className='mb-0 product_name'>
                                            {`${val.productName}`}
                                        </p>
                                        <p className='mb-0 bom_id'>
                                            {`${val.selected_bom[0]?.bomId}`}
                                        </p>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-3">
                        <div className="row gx-1">
                            <div className="col-12 col-md-6">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="lbl-style roboto-bold">Plan Date</label>
                                <DatePicker
                                    id="fromDate"
                                    className="form-control form-control-sm"
                                    // onChange={(date) => {
                                    //     console.log("date", date);
                                    // }}                            
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Plan Date"
                                    selected={new Date(StepOneSavedData.formData.plan_date)}
                                    disabled={true}
                                />

                            </div>
                            <div className="col-12 col-md-6">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="lbl-style roboto-bold">Delivery Date</label>
                                <DatePicker
                                    id="fromDate"
                                    className="form-control form-control-sm"
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="Delivery Date"
                                    selected={new Date(StepOneSavedData.formData.delivery_date)}
                                    disabled={true}
                                />

                            </div>
                        </div>


                        {routeListStatus == 'loading' ?
                            <div className="text-center mt-4">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            : <>
                                {routes?.route_list?.length == 0 ? null :
                                    <div className="form-group typeahead-container mt-2">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="lbl-style roboto-bold"
                                        >
                                            {constLabel?.lbl_routes
                                                ? constLabel.lbl_routes
                                                : "Select Route"}
                                        </label>

                                        <Typeahead
                                            id="product_route_dropdown"
                                            labelKey="routesname"
                                            multiple={false}
                                            options={routes.route_list}
                                            placeholder="Select route"
                                            selected={!selected_route || Object.keys(selected_route)?.length == 0 ?
                                                [] : [selected_route]}
                                            onChange={(selected) => {
                                                console.log("selected", selected);
                                                console.log("selectedProduct", selectedProduct);
                                                if (selected.length > 0) {
                                                    const payload = {
                                                        product_id: selectedProduct.uuid,
                                                        routes_uuid: selected[0].routes_id
                                                    }
                                                    dispatch(fetchRoutesOpr({
                                                        payload,
                                                        startdate: new Date(StepOneSavedData.formData.plan_date),
                                                        enddate: new Date(StepOneSavedData.formData.delivery_date),
                                                        selected_route: selected[0],
                                                        StepOneSavedData
                                                    }));

                                                } else {
                                                    dispatch(reset_operations());
                                                    dispatch(reset_state());
                                                }
                                            }}
                                            emptyLabel="Routes not available"
                                            size="sm"
                                        />

                                        <span className="arrow-icon"></span>
                                    </div>
                                }



                                {routes?.global_route_list?.length == 0 ? null :

                                    <div className="form-group typeahead-container mt-2">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="lbl-style roboto-bold"
                                        >
                                            {constLabel?.lbl_routes
                                                ? constLabel.lbl_routes
                                                : "Select Route"}
                                        </label>

                                        <Typeahead
                                            id="global_route_dropdown"
                                            labelKey="routeName"
                                            multiple={false}
                                            options={routes.global_route_list}
                                            placeholder="Select route"
                                            selected={!selected_route || Object.keys(selected_route)?.length == 0 ?
                                                [] : [selected_route]}
                                            onChange={(selected) => {
                                                console.log("selected", selected);

                                                if (selected.length > 0) {
                                                    const temp_operation_list = [];
                                                    selected[0].routeData.forEach(element => {
                                                        const item = {
                                                            uuid: null,
                                                            sequence_no: element.sequence_no,
                                                            operationname: element.operationname,
                                                            operation_id: element.operation_id,
                                                            machine: [{
                                                                uuid: null,
                                                                cycle_time: element.cycle_time,
                                                                serialNo: element.serialNo,
                                                                machine_id_id: element.machine_id_id,
                                                                operation_id_id: element.operation_id,
                                                                name: element.machine_name
                                                            }],
                                                            operation_details: con_glo_oper_json_to_oper_json(element)
                                                        }
                                                        temp_operation_list.push(item);
                                                    });

                                                    const prod_index = base_data.findIndex((p) =>
                                                        p.uuid === selectedProduct.uuid);

                                                    let reversedOpr = [];
                                                    try {
                                                        reversedOpr = temp_operation_list.sort((a, b) =>
                                                            parseInt(b.sequence_no) - parseInt(a.sequence_no));

                                                    } catch (err) {
                                                        console.log("err", err);
                                                        reversedOpr = temp_operation_list;
                                                    }

                                                    // here we are adding start date end date dynamically through fn
                                                    const newOperations = assignDatesToOperationsReverse(
                                                        // [...temp_operation_list].reverse(),
                                                        [...reversedOpr],
                                                        new Date(StepOneSavedData.formData.plan_date),
                                                        new Date(StepOneSavedData.formData.delivery_date),
                                                        base_data[prod_index]?.selected_route?.route_operation_list,
                                                        StepOneSavedData
                                                    );
                                                    console.log("newOperations", newOperations);
                                                    dispatch(set_global_route_opr(
                                                        { operlist: newOperations, selected_global_route: selected[0] }));
                                                } else {
                                                    dispatch(reset_operations());
                                                    dispatch(reset_state());
                                                }
                                            }}
                                            positionFixed
                                            emptyLabel="Routes not available"
                                            size="sm"
                                        />

                                        <span className="arrow-icon"></span>

                                    </div>
                                }

                            </>}

                        {operation_list_status == 'loading' ?
                            <div className="text-center mt-4">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            <div className="operation_cards mt-2">
                                {operation_list.length > 0 ?
                                    <div className="notes">
                                        {productionplanDetails?.workorder?.length == 0 &&
                                            <div className="d-flex">
                                                <div className="green_indicator"></div>
                                                <span className="ms-2">Indicates job card has been created</span>
                                            </div>
                                        }

                                        <div className="d-flex">
                                            <input checked type="checkbox" className="form-check-input" readOnly />
                                            <span className="ms-2">Indicates operation is selected</span>
                                        </div>
                                    </div>
                                    : null}

                                {operation_list.map((val, ind) => (
                                    <div className={`card ${val.create_job_card ? "active" : "normal"}`} key={ind}>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0 roboto-bold">{val.operation_details?.operationName}</p>

                                            <input type="checkbox"
                                                className="form-check-input"
                                                checked={val.operation_details.uuid == selected_opr?.operation_details?.uuid}
                                                value={val.operation_details.uuid}
                                                onClick={() => {
                                                    // console.log()
                                                    dispatch(handle_check_box({
                                                        value: !val.operation_details?.selected,
                                                        operation: val,
                                                        ind: ind
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="row form-group gx-1 mt-1">
                                            <label className="col-12 col-md-4 lbl-style my-auto">Start Date</label>
                                            <div className="col-12 col-md-8">

                                                <DatePicker
                                                    id={"fromDate" + ind}
                                                    className="form-control form-control-sm"
                                                    selected={val.operation_details?.startDate || new Date()}
                                                    onChange={(date) => {
                                                        console.log("date", date);

                                                        if (new Date(date) >= new Date(val.operation_details.endDate)) {
                                                            call_sweet_alert("Cannot Proceed",
                                                                "Start date should be less than end date");
                                                            return
                                                        }
                                                        dispatch(handle_date_change({
                                                            calledBy: 'startDate',
                                                            value: new Date(date),
                                                            operation: val
                                                        }));
                                                    }}
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                    showTimeSelect
                                                    dateFormat="dd/MM/yyyy h:mm aa"
                                                    withPortal
                                                    showTimeInput
                                                    placeholderText="Start Date"
                                                    minDate={new Date(StepOneSavedData.formData.plan_date)}
                                                    maxDate={new Date(StepOneSavedData.formData.delivery_date)}
                                                    excludeDates={disableDates}
                                                />

                                            </div>
                                        </div>

                                        <div className="row form-group gx-1 mt-1">
                                            <label className="col-12 col-md-4 lbl-style my-auto">End Date</label>
                                            <div className="col-12 col-md-8">
                                                <DatePicker
                                                    id={"endDate" + ind}
                                                    className="form-control form-control-sm"
                                                    selected={val.operation_details?.endDate || new Date()}
                                                    onChange={(date) => {
                                                        console.log("date", date);
                                                        if (new Date(date) <= new Date(val.operation_details.startDate)) {
                                                            call_sweet_alert("Cannot Proceed",
                                                                "End date should be greater than start date");
                                                            return
                                                        }

                                                        dispatch(handle_date_change({
                                                            calledBy: 'endDate',
                                                            value: new Date(date),
                                                            operation: val
                                                        }));
                                                    }}
                                                    showYearDropdown
                                                    scrollableMonthYearDropdown
                                                    showTimeSelect
                                                    dateFormat="dd/MM/yyyy h:mm aa"
                                                    withPortal
                                                    showTimeInput
                                                    placeholderText="End Date"
                                                    minDate={new Date(StepOneSavedData.formData.plan_date)}
                                                    maxDate={new Date(StepOneSavedData.formData.delivery_date)}
                                                    excludeDates={disableDates}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>


                    <div className="col-12 col-md-9">
                        <div className='pb-2'>
                            <i><p className="mb-0">
                                <span className="text-danger roboto-bold">Note - </span>
                                Click on the empty space in the date box to create a job card for that employee.</p></i>
                        </div>

                        {emp_list_status == "loading" ?
                            <div className="text-center mt-4">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            :
                            <Calendar
                                defaultDate={
                                    new Date(new Date(selected_opr?.operation_details?.startDate).setHours(0, 0, 0, 0))
                                    || new Date(new Date(StepOneSavedData.formData.plan_date).setHours(0, 0, 0, 0))}
                                defaultView={Views.WEEK}
                                resources={emp_list}
                                resourceIdAccessor="uuid"
                                resourceTitleAccessor="FullName"
                                events={events}
                                key={events.length}
                                startAccessor={(event) => new Date(event.start_time)}
                                endAccessor={(event) => new Date(event.end_time)}
                                titleAccessor={(event) => event.productOperDetails?.operationname}
                                tooltipAccessor={(event) => `operation - ${event?.operationDetails?.operationName}`}
                                localizer={localizer}
                                views={{
                                    week: MyWeek,
                                }}
                                style={{ height: "90vh" }}
                                // min={new Date(2025, 0, 1, 10)}
                                // max={new Date(2025, 0, 1, 19)}
                                // onNavigate={(date, view, action) => {
                                //     console.log(action)
                                // }}
                                // onView={(view) => {
                                //     console.log("view", view);
                                // }}
                                onSelectEvent={(event) => {
                                    setVisible({
                                        state: true, data: null,
                                        displayData: event
                                    });
                                    console.log("event", event);
                                }}
                                selectable={true}
                                popup={true}
                                onSelectSlot={(slot) => {
                                    setVisible({
                                        state: true,
                                        data: slot,
                                        displayData: null
                                    });

                                    console.log("slot", slot);
                                    console.log("selectedProduct", selectedProduct);
                                    console.log("selected_route", selected_route);
                                    console.log("selected_opr", selected_opr);
                                }}
                                eventPropGetter={(event) => {
                                    const backgroundColor =
                                        colorMapper[event.resourceId] || '#DAE5D0'; // Default color
                                    return { style: { backgroundColor } };
                                }}
                            />
                        }
                        {/* <EmployeeSlider
                        onJobCardCreate={handleJobCardCreate}
                        onJobCardDelete={handleJobCardDelete}
                    /> */}
                    </div>

                </div>

                <div className='d-flex justify-content-end mt-4'>
                    <button className='btn btn-grey px-4'
                        onClick={() => {
                            prevStep();
                        }}
                    >Previous</button>
                    <button
                        disabled={productionplanDetails?.workorder?.length > 0}
                        onClick={() =>
                            submitStep()}
                        className='btn button-primary px-4 ms-0 ms-md-2'
                    >Next</button>


                </div>
            </div>



            {
                visible.state &&
                <JobCardModalV3
                    visible={visible}
                    setVisible={setVisible}
                    fetch_emp_job_cards={fetch_emp_job_cards}
                    events={events}
                />
            }
        </React.Fragment >

    );
};

export default CalenderViewV3;
