import React, { useState, useEffect } from "react";
import { faAdd, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Trash2 } from "react-feather";
import DatePicker from "react-datepicker";
import {
  convertDateYYMMDD,
  getLabels,
} from "../../../../common/Functions/CommonFunctions";
import {
  getCustomer,
} from "../../../../api";
import { toast } from "react-toastify";
import * as name_const from "../../../../common/Constants/constants"

const schema = yup
  .object()
  .shape({
    inward_date: yup
      .date()
      .typeError("Please select inward date !")
      .required("Please select inward date !"),
    heat_no: yup
      .string()
      .required("Please enter heat no!"),
    customer: yup
      .array()
      .min(1, "Please select customer!")
      .required("Please select customer!"),
    weight_rcpl: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid rcpl weight",
      excludeEmptyString: true,
    }),
    weight_transport: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid transport weight",
      excludeEmptyString: true,
    }),
    forwarding: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid forwarding",
      excludeEmptyString: true,
    }),
    total: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid total",
      excludeEmptyString: true,
    }),
    gst: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid gst",
      excludeEmptyString: true,
    }),
    adj: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid adj",
      excludeEmptyString: true,
    }),
    net_amt: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid net amount",
      excludeEmptyString: true,
    }),
    grand_amt: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid grand amount",
      excludeEmptyString: true,
    }),
    freight: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid freight",
      excludeEmptyString: true,
    }),
    invoiceWt: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid invoice weight",
      excludeEmptyString: true,
    }),
    qty: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
      .required("Please enter accepted quantity!"),
    quantity: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid quantity")
      .required("Please enter quantity!"),
   
  })
  .required();

function EditCustomerSupplied(props) {
  const {
    rm_type,
    currIndex,
    rmi_data,
    setRMI_Data,
    setRMIModal,
    inwLineItem,
  } = props;

  let existingInd = rmi_data?.findIndex((item) => item.ind === currIndex);

  const [nc_flag, setNC_Flag] = useState(
    (inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type == "BAR"
      ? rmi_data?.[existingInd]?.acceptyqty
      : rmi_data?.[existingInd]?.qty) < rmi_data?.[existingInd]?.quantity
      ? true
      : false
  );

  const [customerList, setCustomerList] = useState({
    data: [],
    loading: false,
  });
  const [constLabel, setConstLabel] = useState({});
  const loc_storage = JSON.parse(localStorage.getItem("form-data"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [],
    },
  });

  let { errors } = formState;

  const {
    fields: fields,
    append: append,
    remove: remove,
  } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    setConstLabel(getLabels("Purchase", "RMI"));
    console.log("inwLineItem", inwLineItem);
    fetchCustomers();
    setValue(
      "quantity",
      inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type == "BAR"
        ? rmi_data?.[existingInd]?.qty
        // : inwLineItem.data?.[currIndex]?.received_qty
        : loc_storage?.received_qty == inwLineItem.data?.[currIndex]?.received_qty ? inwLineItem.data?.[currIndex]?.received_qty : loc_storage?.received_qty
    );
    setValue("po_number", rmi_data?.[existingInd]?.ponum);
    setValue("price", loc_storage?.amount == rmi_data?.[existingInd]?.price ? rmi_data?.[existingInd]?.price : loc_storage?.amount);
    setValue(
      "challanno",
      loc_storage && loc_storage !== null ? loc_storage?.invoiceNo : ""
    );
    if (existingInd === -1) {
      setValue(
        "uom",
        inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type == "BAR"
          ? "NOS"
          : inwLineItem.data?.[currIndex]?.unit
      );
      setValue(
        "challanno",
        loc_storage && loc_storage !== null ? loc_storage?.invoiceNo : ""
      );
      if (inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type !== "BAR") {
        setValue("qty", loc_storage?.received_qty);
      } else {
        setValue(
          "qty",
          rmi_data?.[existingInd]?.acceptyqty
        );
      }
    } 
    if (inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type !== "BAR") {
      handleAppend({
        target: {
          value: loc_storage.received_qty !== rmi_data?.[existingInd]?.qty ? loc_storage.received_qty : rmi_data?.[existingInd]?.qty,
        },
      });
    }
  }, []);

  useEffect(() => {
    const existingIndex = rmi_data?.findIndex((item) => item.ind === currIndex);
    if (rmi_data.length > 0 && existingIndex !== -1) {
      let abc = [];
      // let pqr = [];

      setValue("heat_no", rmi_data?.[existingIndex]?.heat_no);
      setValue("customer", [
        { partyname: rmi_data?.[existingIndex]?.suppliername },
      ]);

      if (inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type == "BAR") {
        
        setValue("inspectionstatus", rmi_data?.[existingIndex]?.inspectionstatus);
      }
      
      setValue(
        "inward_date",
        rmi_data?.[existingIndex]?.inward_date !== null
          ? new Date(rmi_data?.[existingIndex]?.inward_date)
          : null
      );
      setValue(
        "lengthDetailsflag",
        inwLineItem.data?.[existingIndex]?.productid?.materialTypeDetail
          ?.material_type === "BAR"
          ? true
          : false
      );
      setValue("srmt", [rmi_data?.[existingIndex]?.srmt]);
      setValue("challanno", rmi_data?.[existingIndex]?.challanno);
      setValue("price", loc_storage?.amount == rmi_data?.[existingIndex]?.price ? rmi_data?.[existingIndex]?.price : loc_storage?.amount);
      setValue("sales_order_no", rmi_data?.[existingIndex]?.ponum);
      setValue(
        "uom",
        inwLineItem.data?.[existingIndex]?.productid?.materialTypeDetail
          ?.material_type == "BAR"
          ? "NOS"
          : rmi_data?.[existingIndex]?.uom || rmi_data?.[existingIndex]?.unit
      );
      setValue("material_code", inwLineItem.data?.[currIndex]?.material_code || rmi_data?.[existingInd]?.material_code);
      if (inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type !== "BAR") {
        setValue('qty', loc_storage?.received_qty);
      } else {
        setValue(
          "qty", rmi_data?.[existingInd]?.acceptyqty ? rmi_data?.[existingInd]?.acceptyqty : rmi_data?.[existingInd]?.qty
        );
      }

      setValue(
        "quantity",
        inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type == "BAR"
          ? rmi_data?.[existingInd]?.quantity
          // : inwLineItem.data?.[currIndex]?.received_qty
          : loc_storage?.received_qty == inwLineItem.data?.[currIndex]?.received_qty ? inwLineItem.data?.[currIndex]?.received_qty : loc_storage?.received_qty
      );

      if (rmi_data?.[existingIndex]?.barLengthDetails?.length > 0) {
        for (
          let x = 0;
          x < rmi_data?.[existingIndex]?.barLengthDetails?.length;
          x++
        ) {
          if (rmi_data?.[existingIndex]?.barLengthDetails[x]?.uuid) {
            abc.push({
              uuid: rmi_data?.[existingIndex]?.barLengthDetails[x]?.uuid,
              bar_no: rmi_data?.[existingIndex]?.barLengthDetails[x]?.bar_no,
              cutlength:
                rmi_data?.[existingIndex]?.barLengthDetails[x]?.cutlength,
            });
          } else {
            abc.push({
              bar_no: rmi_data?.[existingIndex]?.barLengthDetails[x]?.bar_no,
              cutlength:
                rmi_data?.[existingIndex]?.barLengthDetails[x]?.cutlength,
            });
          }
        }
      }
      setValue("items", abc);
    }
  }, [rmi_data, currIndex]);

  function fetchCustomers() {
    setCustomerList({ ...customerList, loading: true, data: [] });
    getCustomer().then(
      (res) => {
        const result = res.data?.data?.filter((item) => item.organization !== null || item.name !== null);
        const parties = result
          .filter(
            (item) =>
              item.partyType.trim().toUpperCase() === name_const.SUPPLIER.trim().toUpperCase() ||
              item.partyType.trim().toUpperCase() === name_const.CUSTOMER.trim().toUpperCase()
          )
          .map((item) => ({
            ...item,
            partyname:
              item.partyType.trim().toUpperCase() === name_const.SUPPLIER.trim().toUpperCase()
                ? item.name
                : item.organization,
          }));
        setCustomerList({
          ...customerList,
          loading: false,
          data: parties,
        });
      },
      (err) => {
        setCustomerList({ ...customerList, loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    let reqLoad = [];
    // let reqLoad1 = [];
    if (data !== undefined && data !== "") {
      if (
        data.items?.length > 0 &&
        inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail
          ?.material_type == "BAR") {
        for (let i = 0; i < data.items?.length; i++) {
          let len_detail;
          if (rmi_data?.[existingInd]?.barLengthDetails?.length > 0) {
            // const ex_index = rmi_data?.[existingInd]?.barLengthDetails.findIndex((item) => item.bar_no == data.items?.[i]?.bar_no);
            const ex_index = rmi_data?.[existingInd]?.barLengthDetails.findIndex(
              (item) => item.uuid == data.items?.[i]?.uuid
            );
            let obj = rmi_data?.[existingInd]?.barLengthDetails?.[ex_index];
            //  const {cutlength, ...rest} = obj

            len_detail = {
              uuid: obj?.uuid,
              bar_no: data.items?.[i]?.bar_no,
              cutlength: data.items?.[i]?.cutlength,
            };
            reqLoad.push(len_detail);
          } else {
            len_detail = {
              bar_no: data.items?.[i]?.bar_no,
              cutlength: data.items?.[i]?.cutlength,
            };
            reqLoad.push(len_detail);
          }
        }
      }

      const payload = {
        ind: currIndex,
        material_code: data.material_code,
        uuid: rmi_data?.[existingInd]?.uuid,
        toolinvenId: rmi_data?.[existingInd]?.toolinvenId,
        suppliername: data.customer?.[0]?.partyname,
        ponum: data.sales_order_no,
        challanno: data.challanno,
        heat_no: data.heat_no,
        inspectionstatus: data.inspectionstatus,
        inward_date: convertDateYYMMDD(data.inward_date, "-"),
        lengthDetailsflag:
          inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail
            ?.material_type === "BAR"
            ? true
            : false,
        price: data.price.toString(),
        qty: data.qty,
        srmt: rm_type?.[0]?.typename,
        unit: data.uom,
        // material_code: rmi_data?.[existingInd]?.material_code || mat_code,
        material_code: inwLineItem.data?.[currIndex]?.material_code,
        // NCDetails: reqLoad1,
        barLengthDetails: reqLoad,
      };

      // console.log("payload", payload);

      if (
        inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type === "BAR"
      ) {
        payload.quantity = data.quantity.toString();
      }

      let tempRMI_Data = [...rmi_data];

      const existingIndex = tempRMI_Data.findIndex(
        (item) =>
          item.uuid === payload.uuid && item.srmt === rm_type?.[0]?.typename
      );
      if (existingIndex !== -1) {
        // If the index is found, update the object at that index
        tempRMI_Data[existingIndex] = payload;
      } else {
        // If the index is not found, push the new payload to the array
        delete payload.uuid;
        delete payload.toolinvenId;
        tempRMI_Data.push(payload);
      }

      setRMI_Data(tempRMI_Data);
      localStorage.removeItem("form-data");
      setRMIModal(false);
    }
  };

  function handleAppend(e) {
    const ord_qty = getValues("quantity");
    let i;
    if (
      parseInt(e.target.value) !== undefined &&
      parseInt(e.target.value) !== ""
    ) {
      if (inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type !== "BAR") {
        if (parseInt(loc_storage.received_qty) > parseInt(e.target.value)) {
          toast.error("Received qty and accepted qty should be same", {
            autoClose: 2000,
          });
          return;
        }
      }

      if (parseInt(ord_qty) >= parseInt(e.target.value)) {
        for (i = 0; i < parseInt(e.target.value); i++) {
          let arr = rmi_data?.[existingInd]?.barLengthDetails;
          let val = arr?.[i];
          if (val && val !== undefined) {
            append({
              uuid: val.uuid,
              bar_no: inwLineItem.data?.[currIndex]?.material_code || rmi_data?.[existingInd]?.material_code + "__" + (i + 1),
              cutlength: val.cutlength,
            });
          } else {
            append({ bar_no: inwLineItem.data?.[currIndex]?.material_code || rmi_data?.[existingInd]?.material_code + "__" + (i + 1), cutlength: "0" });
          }
        }
      }

      if (ord_qty !== undefined && ord_qty !== "") {
        if (parseInt(ord_qty) == parseInt(e.target.value)) {
          setValue("inspectionstatus", "Ok");
          setNC_Flag(false);
        } else if (
          parseInt(ord_qty) > parseInt(e.target.value) &&
          parseInt(e.target.value) >= 1
        ) {
          setValue("inspectionstatus", "Partial Ok");
          setNC_Flag(true);
        } else if (
          parseInt(e.target.value) == 0 ||
          parseInt(e.target.value) == ""
        ) {
          setValue("inspectionstatus", "Reject");
        } else if (parseInt(e.target.value) > parseInt(ord_qty)) {
          // setNC_Flag(false);
          toast.error("Accepted quantity should be less than quantity", {
            autoClose: 2000,
          });
          setValue("inspectionstatus", "");
        } else {
          setValue("inspectionstatus", "");
        }
      }
    } else {
      setValue("items", []);
    }

    if (parseInt(e.target.value) !== "") {
      if (fields.length >= 0) {
        remove(fields);
      }
    }
  }

  return (
    <div className="card border-0">
      <div className="card-body">
        <form action="">
          <div className="row mt-3">
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_inward_date
                    ? constLabel?.lbl_inward_date
                    : "Inward Date"}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name="inward_date"
                  control={control}
                  defaultValue={new Date()}
                  render={({ field }) => (
                    <DatePicker
                      className={
                        errors.inward_date
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      {...field}
                      showYearDropdown
                      scrollableMonthYearDropdown
                      dateFormat="dd/MM/yyyy"
                      defaultValue={new Date()}
                      selected={field.value || null}
                      maxDate={new Date()}
                      placeholderText="Select inward date"
                      autoComplete="off"
                      isInvalid={errors.inward_date ? true : false}
                    />
                  )}
                />
                {errors.inward_date && (
                  <span className="text-danger err-msg">
                    {errors.inward_date.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_sales_order
                    ? constLabel?.lbl_sales_order
                    : "Sales Order"}
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter sales order no"
                  name="sales_order_no"
                  {...register("sales_order_no")}
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_customer_name
                    ? constLabel?.lbl_customer_name
                    : "Customer Name"}
                  <span className="text-danger"> *</span>
                </label>
                <Controller
                  name={`customer`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      className={"mt-2"}
                      labelKey="partyname"
                      multiple={false}
                      options={customerList.data}
                      isLoading={customerList.loading}
                      placeholder="Select customer name"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.customer ? true : false}
                    />
                  )}
                />
                {errors.customer && (
                  <span className="text-danger err-msg">
                    {errors.customer.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_challan_no
                    ? constLabel?.lbl_challan_no
                    : "Challan No"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter challan number"
                  name="challanno"
                  {...register("challanno")}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_heat_no
                    ? constLabel?.lbl_heat_no
                    : "Heat No"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={
                    errors.heat_no
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter heat number "
                  name="heat_no"
                  {...register("heat_no")}
                />
                {errors.heat_no && (
                  <span className="text-danger err-msg">
                    {errors.heat_no.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_material_code
                    ? constLabel?.lbl_material_code
                    : "Material code"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  disabled
                  placeholder="Enter material code"
                  value={inwLineItem.data?.[currIndex]?.material_code}
                  name="material_code"
                  {...register("material_code")}
                />
              </div>
            </div>
            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_rmi_price
                    ? constLabel?.lbl_rmi_price
                    : "RMI Price"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  defaultValue={inwLineItem.data?.[currIndex]?.price}
                  placeholder="Enter rmi price"
                  name="price"
                  {...register("price")}
                />
              </div>
            </div>
          </div>
          <hr className="my-4" />

          <h5 className="roboto-bold ">{"Product Details"}</h5>
          <div className="row mt-3">
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_name
                    ? constLabel?.lbl_product_name
                    : "Product Name"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  defaultValue={inwLineItem.data?.[currIndex]?.productname}
                  placeholder="Enter product name"
                  name="product_name"
                  {...register("product_name")}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_uom ? constLabel?.lbl_uom : "UOM"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter UOM"
                  name="uom"
                  {...register("uom")}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_quantity
                    ? constLabel?.lbl_quantity
                    : "Quantity"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  placeholder="Enter quantity"
                  name="quantity"
                  {...register("quantity")}
                  disabled={inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail?.material_type == "BAR" ? false : true }
                />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_accepted_qty
                    ? constLabel?.lbl_accepted_qty
                    : "Accepted Qty"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  // defaultValue={
                  //   loc_storage && loc_storage?.received_qty !== null
                  //     ? loc_storage?.received_qty
                  //     : ""
                  // }
                  placeholder="Enter accepted quantity"
                  name="qty"
                  {...register("qty", {
                    onChange: (e) => {
                      handleAppend(e);
                    },
                  })}
                  // disabled={poWithItem.data?.PO_line_item?.[currIndex]?.material_type == "BAR" || poWithItem.data?.PO_line_item?.[existingInd]?.material_type == "BAR" ? false : true }
                />
                {errors.qty && (
                  <span className="text-danger err-msg">
                    {errors.qty.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_inspection_status
                    ? constLabel?.lbl_inspection_status
                    : "Inspection Status"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  className={"form-control mt-2"}
                  type="text"
                  disabled
                  placeholder="Enter inspection status"
                  name="inspectionstatus"
                  {...register("inspectionstatus")}
                />
              </div>
            </div>

            <div className="col-12 col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"NC Details"}</label>
                <div className="form-check mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    style={{ width: "20px", height: "20px" }}
                    onChange={() => setNC_Flag(!nc_flag)}
                    checked={nc_flag}
                  />{" "}
                  <label>{"Has NC ?"}</label>
                </div>
              </div>
            </div>
          </div>

          {inwLineItem.data?.[currIndex]?.productid?.materialTypeDetail
            ?.material_type == "BAR" ? (
            <>
              <hr className="my-4" />
              <h5 className="roboto-bold ">{"Length Details"}</h5>

              <div
                className="py-3"
                style={{ overflowX: "scroll", minHeight: "50vh" }}
              >
                <table className="table table-responsive table-bordered table-sm">
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope="col">
                        {constLabel?.lbl_bar_no
                          ? constLabel?.lbl_bar_no
                          : "Bar No"}
                      </th>
                      <th scope="col">
                        {constLabel?.lbl_bar_length
                          ? constLabel?.lbl_bar_length
                          : "Bar Length"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td width={"30%"}>
                          <input
                            className={
                              errors.items?.[index]?.bar_no
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            disabled
                            name={`items[${index}].bar_no`}
                            // value={mat_code + "__" +(index + 1)}
                            {...register(`items[${index}].bar_no`)}
                            placeholder="Enter bar number"
                          />

                          {errors.items?.[index]?.bar_no && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.bar_no.message}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.cutlength
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            defaultValue={0}
                            name={`items[${index}].cutlength`}
                            {...register(`items[${index}].cutlength`)}
                            placeholder="Enter cut length"
                          />
                          {errors.items?.[index]?.cutlength && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.cutlength.message}
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : null}
          <hr className="my-4" />

          <div className="d-flex justify-content-end">
            <button className="btn btn-yellow" onClick={handleSubmit(onSubmit)}>
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCustomerSupplied;
