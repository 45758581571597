import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  convertDateYYMMDD,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowLeft,
  faFileExcel,
} from "@fortawesome/free-solid-svg-icons";
import {
  get_WorkOrder,
  getProdConfig,
  getOperatorList,
  getProdList,
  get_inspection_type,
  getProductAttribute,
  create_job_card,
  getGlobalRouting,
} from "../../../api";
import DatePicker from "react-datepicker";
import { Trash2 } from "react-feather";
import Inspection_Data from "./InspectinonData/Inspection_Data";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

const schema = yup
  .object()
  .shape({
    work_order: yup
      .array()
      .min(1, "Please select workorder !")
      .required("Please select workorder !"),
    startDate: yup
      .date()
      .typeError("Please select start date!")
      .required("Please select start date!"),
    startTime: yup
      .date()
      .typeError("Please select start time!")
      .required("Please select start time!"),
    endDate: yup
      .date()
      .typeError("Please select end date!")
      .required("Please select end date!"),
    endTime: yup
      .date()
      .typeError("Please select end time!")
      .required("Please select end time!"),
    target_qty: yup
      .string()
      .matches(/^[1-9][0-9]*$/, "Enter valid target qty")
      .required("Enter target qty !"),
    // produced_qty: yup
    //   .string()
    //   .matches(/^[0-9]+$/, "Enter valid produced quantity")
    //   .required("Please enter produced quantity !"),
    operator: yup
      .array()
      .min(1, "Please select operator !")
      .required("Please select operator !"),
    machine: yup
      .array()
      .min(1, "Please select machine !")
      .required("Please select machine !"),
  })
  .required();

function Create_Job_Card() {
  let navigate = useNavigate();
  let location = useLocation();
  const [constLabel, setConstLabel] = useState({});
  const [workOrders, setWorkOrders] = useState({ data: [], loading: false });
  const [operations, setOperations] = useState({ data: [], loading: false });
  const [oprtList, setOprtList] = useState({ data: [], loading: false });
  const [start_date, setStart_Date] = useState(new Date());
  const [end_date, setEnd_Date] = useState(new Date());
  const [start_time, setStart_Time] = useState(new Date());
  const [end_time, setEnd_Time] = useState(new Date());

  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [insType_List, setInsType_List] = useState({
    data: [],
    loading: false,
  });

  const [lockBtn, setLockBtn] = useState(false);

  const [prod_attr, setProd_Attr] = useState({ data: [], loading: false });
  const [inspListobj, setInspListobj] = useState({});

  const [prodList, setProdList] = useState({ data: [], loading: false });
  const loginData = useSelector((state) => state.auth);

  const [jobStatus, setJobStatus] = useState([
    { id: 1, statusName: "Open" },
    { id: 2, statusName: "In Progress" },
    { id: 3, statusName: "Hold" },
    { id: 4, statusName: "Closed" },
    { id: 5, statusName: "Cancelled" }
  ]);

  // const [workShift, setWorkShift] = useState([
  //   { id: 1, shiftName: "Morning" },
  //   { id: 2, shiftName: "Afternoon" },
  //   { id: 3, shiftName: "Night" },
  // ]);

  const { handleSubmit, register, control, formState, watch, reset, setValue, setError, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [
          {
            inspection_type: [],
            insp_name: "",
            inspection_date: null,
            inspection_remark: "",
          },
        ],
      },
    });

  let { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const selProdOpr = watch("prod_operation");
  const selMachine = watch("machine");
  const selWO = watch("work_order");

  useEffect(() => {
    // console.log("location.state", location.state);

    // if user is navigating from workorder update location.state will have data about workorder

    if (location && location.state && location.state?.work_order_details?.work_order_no) {
      delete location?.state?.work_order_details?.bom_details?.bom_rid;

      // console.log("location.state", location.state);
      setValue("work_order", [location.state?.work_order_details], { shouldValidate: true });
      setValue("prod_operation", [location.state?.operation], { shouldValidate: true });
    }

    setConstLabel(getLabels("Production", "Job Card"));
    fetchWorkOrder();
    // fetchProdList(null);
    fetchOperatorList();
    fetchInspectionType();
  }, []);

  useEffect(() => {
    if (selProdOpr !== undefined && selProdOpr.length > 0) {
      // console.log("selProdOpr", selProdOpr);

      setValue("startDate", start_date);
      setValue("startTime", start_time);
      setValue("endDate", end_date);
      setValue("endTime", end_time);

      if(selWO?.[0]?.global_route_uuid !== null){
        const ress = [{
          machine_id_id: selProdOpr?.[0]?.machine_id_id,
          cycle_time: selProdOpr?.[0]?.cycle_time,
          setting_time: selProdOpr?.[0]?.setting_time,
          man_hour_cost: selProdOpr?.[0]?.man_hour_cost,
          machine_name: selProdOpr?.[0]?.machine_name,
          serialNo: selProdOpr?.[0]?.serialNo,
          machineNameSerialNo:
          selProdOpr?.[0]?.serialNo && selProdOpr?.[0]?.serialNo !== ""
              ? selProdOpr?.[0]?.machine_name + " - " + selProdOpr?.[0]?.serialNo
              : selProdOpr?.[0]?.machine_name,
        }];
        
        // setValue("machine", ress, {shouldValidate: true});
        setMachineList({ ...machineList, data: ress, loading: false });
        // console.log("selWO?.[0]?.product_id", selWO?.[0]?.product_id);
        fetchProductAttributes(selWO?.[0]?.product_id);
      } 
      else {
        fetchProductAttributes(selProdOpr?.[0]?.product_id);
        setMachineList({ ...machineList, data: [], loading: true });

        const ress = selProdOpr?.[0]?.machine?.map((item) => ({
          ...item,
          machineNameSerialNo:
            item.serialNo && item.serialNo !== ""
              ? item.name + " - " + item.serialNo
              : item.name,
        }));

        if (ress) {
          setMachineList({ ...machineList, data: ress, loading: false });
        } else {
          setMachineList({ ...machineList, data: [], loading: false });
        }
      }
    }
  }, [selProdOpr]);

  useEffect(() => {
    if (selWO && selWO.length > 0) {
      fetchProdList(selWO[0]?.product_id);
    } else {
      // clearAll();
    }
  }, [selWO]);

  useEffect(() => {
    const calculatePlannedQuantity = (
      st_tm,
      ed_tm,
      setting_time,
      cycle_time
    ) => {
      setting_time =
      setting_time && setting_time !== "" && setting_time !== null ? setting_time : 0;
      // console.log("ed_tm", ed_tm);
      // console.log("st_tm", st_tm);
      const timeDiffInMinutes = (ed_tm - st_tm) / (60 * 1000);
      // console.log("timeDiffInMinutes", timeDiffInMinutes);

      if (timeDiffInMinutes > 0 && cycle_time > 0) {
        const plannedQuantity = Math.ceil(
          (timeDiffInMinutes - parseFloat(setting_time)) /
          parseFloat(cycle_time)
        );
        return plannedQuantity;
      } else {
        return 0;
      }
    };

    if (
      selMachine === null ||
      selMachine === undefined ||
      start_date === null ||
      start_date === undefined ||
      start_time === null ||
      start_time === undefined ||
      end_date === null ||
      end_date === undefined ||
      end_time === null ||
      end_time === undefined
    ) {
      // Handle the case where any of the dependencies are null or undefined
      setValue("target_qty", 0, { shouldValidate: true });
      return;
    }

    let plannedQty = calculatePlannedQuantity(
      start_date,
      end_date,
      (selMachine?.[0]?.settings_time || selMachine?.[0]?.setting_time),
      selMachine?.[0]?.cycle_time
    );

    if (selWO && selWO.length > 0) {
      if (plannedQty > selWO?.[0]?.quantity) {
        setError("target_qty", { type: "custom", message: "Target qty should not exceeds than work order qty" });
        setValue("target_qty", plannedQty);
      } else {
        setValue("target_qty", plannedQty, { shouldValidate: true });
      }
    }



  }, [start_date, start_time, end_date, end_time, selMachine]);

  function fetchWorkOrder() {
    setWorkOrders({ ...workOrders, data: [], loading: true });

    get_WorkOrder({ work_order_status: "Released" }).then(
      (res) => {
        setWorkOrders({ ...workOrders, data: res.data.data, loading: false });
      },
      (err) => {
        setWorkOrders({ ...workOrders, data: [], loading: false });
      }
    );
  }

  function fetchProductOperations(prod_id) {

    setOperations({ ...operations, data: [], loading: true });

    getProdConfig({ product_id: prod_id, routes_uuid: selWO[0]?.route_uuid }).then(
      (res) => {
        // console.log("prod opr res", res);
        setOperations({ ...operations, data: res.data.data, loading: false });
      },
      (err) => {
        setOperations({ ...operations, data: [], loading: false });
      }
    );
  }

  function fetchOperatorList() {
    setOprtList({ ...oprtList, data: [], loading: true });
    getOperatorList({ designation: ["Operator", "Packing and Dispatch Lead"]}).then(
      (res) => {
        const result = res.data.data.map((item) => ({
          ...item,
          fullName:
            item.MiddleName !== null && item.MiddleName !== ""
              ? item.FirstName + " " + item.MiddleName + " " + item.LastName
              : item.FirstName + " " + item.LastName,
        }));
        setOprtList({ ...oprtList, data: result, loading: false });
      },
      (err) => {
        setOprtList({ ...oprtList, data: [], loading: false });
      }
    );
  }

  function newDateTimeForm(date, time) {
    // console.log("date", date);
    // console.log("time", time);
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    // Extracting time components
    let hours = time.getHours().toString().padStart(2, "0");
    let minutes = time.getMinutes().toString().padStart(2, "0");
    let seconds = time.getSeconds().toString().padStart(2, "0");

    // Creating start_time in the desired format (assuming UTC time for the example)
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const onSubmit = (data) => {
    // console.log("data", data);
    setLockBtn(true);
    if (selWO && selWO.length > 0 && Number(data.target_qty) > selWO[0].quantity) {
      setError("target_qty", { type: "manual", message: "Target qty should not exceeds work order qty" });
      return;
    }

    let reqPayload = [];
    const fromDate = newDateTimeForm(data.startDate, data.startTime);
    const toDate = newDateTimeForm(data.endDate, data.endTime);

    const timeDiff = Math.abs(new Date(fromDate) - new Date(toDate));

    for (let i = 0; i < data.items.length; i++) {
      let item = data.items[i];
      if (item.inspection_type.length == 0) {
        continue;
      }
      const lineItems = {
        inspection_type: data.items?.[i]?.inspection_type?.[0]?.inspection_name,
        inspection_name: data.items?.[i]?.insp_name,
        inspection_date: convertDateYYMMDD(
          data.items?.[i]?.inspection_date,
          "-"
        ),
        remarks: data.items?.[i]?.inspection_remark,
        inspection_data: inspListobj[`${i}`],
      };
      reqPayload.push(lineItems);
    }

    // console.log("reqPayload",reqPayload)
    if (data !== "") {
      const payload = {
        work_order_id: data?.work_order?.[0]?.uuid,
        product_id: data?.product?.[0]?.uuid,
        // product_operation_id: data.prod_operation?.[0]?.uuid,
        cycle_time: data?.cycle_time,
        operator: data.operator?.[0]?.uuid,
        start_time: fromDate,
        end_time: toDate,
        total_time_hrs: Math.floor(timeDiff / 3600000).toString(),
        total_time_mins: Math.floor(timeDiff / 60000).toString(),
        total_time_sec: Math.floor(timeDiff / 1000).toString(),
        planned_qty: parseInt(data.target_qty),
        // actual_qty: data.actual_qty,
        status: data?.status?.[0]?.statusName,
        remarks: data.remarks !== "" ? data.remarks : null,
        machine: data.machine?.[0]?.machine_id_id,
        inspection_details: reqPayload,
      };

      if(selWO?.[0]?.global_route_uuid !== null){
        payload.operation_uuid = data.prod_operation?.[0]?.operation_id;
      } else {
        payload.product_operation_id = data.prod_operation?.[0]?.uuid;
        payload.operation_uuid = data.prod_operation?.[0]?.operation_id;
      }

      // console.log("create payload", payload); return;
      
      create_job_card(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Job card created successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              if (location?.state?.jobCardBoard) {
                navigate("/pcms/job-card-board", {
                  state: { ...location.state.work_order_details },
                });
              } else {
                navigate("/pcms/job-card");
              }
            }, 2200);
            // setLockBtn(false);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
          setLockBtn(false);
        }
      );
    } else {
      errors.showMessages();
    }
  };

  const handleFromDateChange = (date) => {
    if (end_date && date > end_date && end_time && date > end_time) {
      return;
    }
    setStart_Date(date);
    setStart_Time(date);
  };

  const handleToDateChange = (date) => {
    if (start_date && date < start_date && start_time && date < start_time) {
      return;
    }
    setEnd_Date(date);
    setEnd_Time(date);
  };

  function fetchProdList(prod_uuid) {
    // setProdList({ ...prodList, data: [], loading: true });

    getProdList({ uuid: prod_uuid }).then(
      (res) => {
        // console.log("product data", res.data.data);
        if (res) {
          setValue("product", res.data.data);
          setValue("partNo", res.data.data?.[0]?.partNo);
          // console.log("selWO?.[0]?.global_route_uuid", selWO?.[0]?.global_route_uuid);
          if(selWO?.[0]?.global_route_uuid !== null){
            fetchGlobalRoute(selWO?.[0]?.global_route_uuid);
          } else {
            fetchProductOperations(res.data.data?.[0]?.uuid);
          }
          
        } else {
          setValue("product", []);
        }
        // let arr = res.data.data.filter(
        //   (item) => item.productName !== null || item.productName !== undefined
        // );
        // setProdList({ ...prodList, data: arr, loading: false });
      },
      (err) => {
        // setProdList({ ...prodList, data: [], loading: false });
      }
    );
  }

  function fetchInspectionType() {
    setInsType_List({ ...insType_List, data: [], loading: true });

    get_inspection_type().then(
      (res) => {
        setInsType_List({
          ...insType_List,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setInsType_List({ ...insType_List, data: [], loading: false });
      }
    );
  }

  function fetchProductAttributes(prod_uuid) {
    setProd_Attr({ ...prod_attr, data: [], loading: true });
    const payload = {
      product_id: prod_uuid,
    };
    getProductAttribute(payload).then(
      (res) => {
        // console.log("attribute res", res);
        console.log("selProdOpr", selProdOpr);

        const result = res.data.data.filter(
          (item) =>
            item.prod_operation_details?.operation_id ===
            selProdOpr?.[0]?.operation_id
        );

        if (result) {
          setProd_Attr({ ...prod_attr, data: result, loading: false });
        } else {
          toast.info("Product attributes not found !", { autoClose: 2000 });
          setProd_Attr({ ...prod_attr, data: [], loading: false });
        }
      },
      (err) => {
        setProd_Attr({ ...prod_attr, data: [], loading: false });
      }
    );
  }

  function clearAll() {
    reset({
      product: [],
      partNo: "",
      status: [],
      prod_operation: [],
      machine: [],
      cycle_time: "",
      target_qty: "",
      // shif: [],
      operator: [],
      // setting_time: "",
      // avg_cycle_time: "",
      // produced_qty: "",
      // down_time: "",
    });
    setMachineList({ data: [] });
    setOperations({ data: [] });
  }

  function handleTargetQtyChange(value) {
    if (selWO && selWO.length > 0 && Number(value) > selWO[0].quantity) {
      setError("target_qty", { type: "manual", message: "Target qty should not exceeds work order qty" });
    } else {
      clearErrors("target_qty");
      setValue("target_qty", value, { shouldValidate: true });
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  function fetchGlobalRoute(glob_route_uuid){
    getGlobalRouting({uuid: glob_route_uuid}).then(
      (res) => {
        console.log("res", res);
        setOperations({ ...operations, data: res.data.data?.[0]?.routeData, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/pcms/job-card");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_create_job_card
                ? constLabel.lbl_create_job_card
                : "Create Job Card"}
            </p>
          </div>
          <div className="card-body">
            <div className="row pb-3">
              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_work_order
                      ? constLabel.lbl_work_order
                      : "Work Order"}
                    <span className="text-danger"> *</span>{" "}
                      {selWO && selWO?.length > 0 ? <em className="body3 text-secondary">Work Order Qty : {selWO?.[0]?.quantity}</em> : null }
                  </label>

                  {/* <Controller
                    name="work_order"
                    control={control}
                    render={({ field }) => (
                      <Typeahead
                        {...field}
                        id="work_order"
                        labelKey="work_order_no"
                        onChange={(selected) => field.onChange(selected)}
                        options={
                          workOrders.data !== undefined ? workOrders.data : []
                        }
                        isLoading={workOrders.loading}
                        placeholder="Select work order no"
                        selected={field.value || []}
                        multiple={false}
                        isInvalid={errors.work_order ? true : false}
                      />
                    )}
                  /> */}

                  <Controller
                    name="work_order"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="work_order_no"
                        multiple={false}
                        options={
                          workOrders.data !== undefined ? workOrders.data : []
                        }
                        isLoading={workOrders.loading}
                        placeholder="Select work order no"
                        // onChange={onChange}
                        onChange={(selected) => {
                          onChange(selected);

                          if (selected.length == 0) {
                            clearAll();
                          }
                          // else {
                          //   fetchProdList(selected?.[0]?.product_id);
                          // }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.work_order ? true : false}
                      />
                    )}
                  />
                  {!workOrders.loading && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.work_order?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_product_name
                      ? constLabel.lbl_product_name
                      : "Product Name"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <Controller
                    name="product"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="productName"
                        multiple={false}
                        // size="sm"
                        // options={
                        //   prodList.data !== undefined ? prodList.data : []
                        // }
                        // isLoading={prodList.loading}
                        placeholder="Select product"
                        disabled
                        // onChange={(selected) => {
                        //   onChange(selected);
                        //   if (selected.length > 0) {
                        //     setValue("partNo", selected?.[0]?.partNo);
                        //     fetchProductOperations(selected?.[0]?.uuid);
                        //   } else {
                        //     clearAll();
                        //   }
                        // }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.product ? true : false}
                      />
                    )}
                  />
                  {!errors.product && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.product?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_part_no
                      ? constLabel.lbl_part_no
                      : "Part No"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <input
                    type="text"
                    className="form-control mt-2"
                    id="partNo"
                    name="partNo"
                    placeholder="Enter part no"
                    disabled
                    // disabled={
                    //   (selProduct && selProduct.length > 0)
                    //     ? true
                    //     : false
                    // }
                    {...register("partNo")}
                  />
                  {/* <span className="text-danger err-msg">
                                {errors.part_no?.message}
                            </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>

                  <Controller
                    name="status"
                    control={control}
                    defaultValue={[{ id: 1, statusName: "Open" }]}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="statusName"
                        multiple={false}
                        defaultSelected={[{ id: 1, statusName: "Open" }]}
                        options={jobStatus}
                        placeholder="Select status"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      // isInvalid={errors.status ? true : false}
                      />
                    )}
                  />
                  {jobStatus.length > 0 && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                        {errors.status?.message}
                    </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_start_date
                      ? constLabel.lbl_start_date
                      : "Start Date"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    control={control}
                    name="startDate"
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.startDate
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select start date"
                        selected={start_date}
                        onChange={(date) => {
                          field.onChange(date);
                          handleFromDateChange(date);
                        }}
                        minDate={
                          // start_date?.getTime() - 30 * 24 * 60 * 60 * 1000
                          new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                        }
                        dateFormat="dd/MM/yyyy"
                        autoComplete="false"
                      />
                    )}
                  />

                  {errors.startDate && (
                    <span className="text-danger err-msg">
                      {errors.startDate.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_start_time
                      ? constLabel?.lbl_start_time
                      : "Start Time"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    control={control}
                    name="startTime"
                    render={({ field }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        className={
                          errors.startTime
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select start time"
                        selected={start_time}
                        onChange={(date) => {
                          field.onChange(date);
                          handleFromDateChange(date);
                        }}
                        // minTime={start_time}
                        // maxTime={(new Date(), 1439)}
                        dateFormat="h:mm aa"
                        autoComplete="false"
                      />
                    )}
                  />

                  {errors.startTime && (
                    <span className="text-danger err-msg">
                      {errors.startTime.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_end_date
                      ? constLabel.lbl_end_date
                      : "End Date"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    control={control}
                    name="endDate"
                    render={({ field }) => (
                      <DatePicker
                        className={
                          errors.endDate
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select end date"
                        selected={end_date}
                        onChange={(date) => {
                          field.onChange(date);
                          handleToDateChange(date);
                        }}
                        minDate={
                          // end_date?.getTime() - 30 * 24 * 60 * 60 * 1000
                          new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                        }
                        dateFormat="dd/MM/yyyy"
                        autoComplete="false"
                      />
                    )}
                  />
                  {errors.endDate && (
                    <span className="text-danger err-msg">
                      {errors.endDate.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_end_time
                      ? constLabel?.lbl_end_time
                      : "End Time"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    control={control}
                    name="endTime"
                    render={({ field }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        className={
                          errors.endTime
                            ? "form-control mt-2 is-invalid"
                            : "form-control mt-2"
                        }
                        placeholderText="Select end time"
                        selected={end_time}
                        onChange={(date) => {
                          field.onChange(date);
                          handleToDateChange(date);
                        }}
                        // minTime={start_time}
                        // maxTime={(new Date(), 1439)}
                        dateFormat="h:mm aa"
                        autoComplete="false"
                      />
                    )}
                  />

                  {errors.endTime && (
                    <span className="text-danger err-msg">
                      {errors.endTime.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <hr className="my-2" />
            <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
              {constLabel?.lbl_job_card_detail
                ? constLabel.lbl_job_card_detail
                : "Job Card Detail"}
            </p>
            <hr className="my-2" />
            <div className="row pb-3">
              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_product_operation
                      ? constLabel.lbl_product_operation
                      : "Product Operation"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="prod_operation"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="operationname"
                        multiple={false}
                        options={
                          operations.data !== undefined ? operations.data : []
                        }
                        isLoading={operations.loading}
                        placeholder="Select product operation"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.prod_operation ? true : false}
                      />
                    )}
                  />
                  {!operations.loading && !errors.prod_operation && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.prod_operation?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_machine
                      ? constLabel.lbl_machine
                      : "Machine"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name={`machine`}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="machineNameSerialNo"
                        multiple={false}
                        options={machineList.data}
                        isLoading={machineList.loading}
                        placeholder="Select machine"
                        onChange={(selected) => {
                          onChange(selected);
                          if (selected.length > 0) {
                            setValue("cycle_time", selected?.[0]?.cycle_time);
                            setValue(
                              "setting_time",
                              selected?.[0]?.settings_time
                            );
                          } else {
                            setValue("cycle_time", "");
                            setValue("setting_time", "");
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.machine ? true : false}
                      />
                    )}
                  />
                  {!machineList.loading && !errors.machine && <span className="arrow-icon"></span>}
                  {errors.machine && (
                    <span className="text-danger err-msg">
                      {errors.machine.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_cycle_time
                      ? constLabel.lbl_cycle_time
                      : "Cycle Time"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="cycle_time"
                    name="cycle_time"
                    placeholder="Enter cycle time"
                    disabled
                    {...register("cycle_time")}
                  />
                  {/* <span className="text-danger err-msg">
                    {errors.part_no?.message}
                  </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_target_qty
                      ? constLabel.lbl_target_qty
                      : "Target Qty"}
                    <span className="text-danger"> *</span>{" "}

                  </label>
                  <input
                    type="text"
                    className={
                      errors.target_qty
                        ? "form-control mt-2 is-invalid"
                        : "form-control mt-2"
                    }
                    id="target_qty"
                    name="target_qty"
                    placeholder="Enter target qty"
                    {...register(`target_qty`, {
                      onChange: debounce((e) => {
                        const value = e.target.value;
                        handleTargetQtyChange(value);
                      }, 300) // Adjust the debounce delay as needed (300ms in this example)
                    })}
                    disabled={loginData.loginData?.data?.response.role_name !==
                      "client_admin" &&
                      loginData.loginData?.data?.response.role_name !==
                      "admin" ? true : false}
                  />
                  <span className="text-danger err-msg">
                    {errors.target_qty?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_actual_qty
                      ? constLabel.lbl_actual_qty
                      : "Actual Qty"}
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="actual_qty"
                    defaultValue={0}
                    name="actual_qty"
                    placeholder="Enter actual qty"
                    {...register("actual_qty")}
                    disabled
                  />
                </div>
              </div>

              {/* <div className="col-12 col-md-2">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_shift ? constLabel.lbl_shift : "Shift"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="shift"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="shiftName"
                        multiple={false}
                        // size="sm"
                        options={workShift}
                        placeholder="Select shift"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.status ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger err-msg">
                        {errors.status?.message}
                    </span>
                </div>
              </div> */}

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_operator
                      ? constLabel.lbl_operator
                      : "Operator"}
                    <span className="text-danger"> *</span>
                  </label>

                  <Controller
                    name="operator"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        className="mt-2"
                        labelKey="fullName"
                        multiple={false}
                        options={
                          oprtList.data !== undefined ? oprtList.data : []
                        }
                        isLoading={oprtList.loading}
                        placeholder="Select operator"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.operator ? true : false}
                      />
                    )}
                  />
                  {!oprtList.loading && !errors.operator && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.operator?.message}
                  </span>
                </div>
              </div>

              {/* <div className="col-12 col-md-3 mt-2">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_setting_time
                      ? constLabel.lbl_setting_time
                      : "Setting Time"}
                     <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="setting_time"
                    name="setting_time"
                    placeholder="Enter setting time"
                    {...register("setting_time")}
                  />
                  <span className="text-danger err-msg">
                    {errors.setting_time?.message}
                  </span> 
                </div>
              </div> */}

              {/* <div className="col-12 col-md-3 mt-2">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_avg_cycle_time
                      ? constLabel.lbl_avg_cycle_time
                      : "Avg. Cycle Time"}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="avg_cycle_time"
                    name="avg_cycle_time"
                    placeholder="Enter avg cycle time"
                    disabled
                    {...register("avg_cycle_time")}
                  />
                  <span className="text-danger err-msg">
                    {errors.avg_cycle_time?.message}
                  </span>
                </div>
              </div> */}

              {/* <div className="col-12 col-md-3 mt-2">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_produced_qty
                      ? constLabel.lbl_produced_qty
                      : "Produced Qty"}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="produced_qty"
                    name="produced_qty"
                    placeholder="Enter produced qty"
                    {...register("produced_qty")}
                  />
                  <span className="text-danger err-msg">
                    {errors.produced_qty?.message}
                  </span>
                </div>
              </div> */}

              {/* <div className="col-12 col-md-3 mt-2">
                <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_down_time
                      ? constLabel.lbl_down_time
                      : "Down Time"}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="down_time"
                    name="down_time"
                    placeholder="Enter down time"
                    {...register("down_time")}
                  />
                  <span className="text-danger err-msg">
                    {errors.down_time?.message}
                  </span>
                </div>
              </div> */}

              <div className="col-12 col-md-3 mt-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_remarks
                      ? constLabel.lbl_remarks
                      : "Remarks"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>
                  <textarea
                    type="text"
                    className="form-control mt-2"
                    id="remarks"
                    name="remarks"
                    placeholder="Enter remarks"
                    {...register("remarks")}
                  >
                  </textarea>
                  {/* <span className="text-danger err-msg">
                    {errors.part_no?.message}
                  </span> */}
                </div>
              </div>

              <div className="col-12 col-md-3 mt-3">
                <label className="lbl-style roboto-bold invisible">
                  {"Unit"}
                </label>
                <div className="d-flex align-items-center mt-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={selProdOpr?.[0]?.serial_number_tracking == true ? true : false}
                      disabled
                      checked={selProdOpr?.[0]?.serial_number_tracking == true ? true : false}
                    // name="can_be_sold"
                    // {...register("can_be_sold")}
                    />
                    <label className="form-check-label lbl-style roboto-bold">
                      {constLabel?.lbl_serial_number_tracking
                        ? constLabel?.lbl_serial_number_tracking
                        : "Serial number tracking"}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {/* Inspection Detail */}
            <hr className="my-2" />
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_inspection_detail
                  ? constLabel.lbl_inspection_detail
                  : "Inspection Detail"}
              </p>
              <div>
                <button className="btn btn-outline-secondary btn-sm">
                  <FontAwesomeIcon
                    style={{ marginRight: "6px" }}
                    icon={faFileExcel}
                    size="sm"
                  />
                  {constLabel?.lbl_inspection_report
                    ? constLabel?.lbl_inspection_report
                    : "Inspection Report"}
                </button>
                <button
                  // style={{ fontSize: "16px" }}
                  className="btn btn-sm button-primary px-2 ms-2"
                  onClick={() => {
                    append({});
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      color: "#ffffff",
                    }}
                    icon={faAdd}
                    size="sm"
                  />
                  {"Add"}
                </button>
              </div>
            </div>
            <hr className="my-2" />

            <div className="row">
              <div className="col-12 col-md-12">
                <table className="table table-responsive table-bordered table-sm">
                  <thead className="table-grey roboto-bold">
                    <tr>
                      <th>
                        {constLabel?.lbl_inspection_type
                          ? constLabel?.lbl_inspection_type
                          : "Inspection Type"}
                      </th>
                      <th>
                        {constLabel?.lbl_inspection_name
                          ? constLabel?.lbl_inspection_name
                          : "Inspection Name"}
                      </th>
                      <th>
                        {constLabel?.lbl_inspection_date_time
                          ? constLabel?.lbl_inspection_date_time
                          : "Inspection Date Time"}
                      </th>
                      <th>
                        {constLabel?.lbl_remarks
                          ? constLabel?.lbl_remarks
                          : "Remarks"}
                      </th>
                      <th>{"Action"}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id}>
                        <td style={{ verticalAlign: "top" }}>
                          <Controller
                            name={`items[${index}].inspection_type`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="inspection_name"
                                multiple={false}
                                options={
                                  insType_List.data !== undefined
                                    ? insType_List.data
                                    : []
                                }
                                isLoading={insType_List.loading}
                                placeholder="Select inspection type"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                              // isInvalid={
                              //   errors.items?.[index]?.inspection_type
                              //     ? true
                              //     : false
                              // }
                              />
                            )}
                          />
                          {/* {errors.items?.[index]?.inspection_type && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.inspection_type.message}
                            </span>
                          )} */}

                          {/* {index + 1 == fields.length && (
                            <button
                              style={{ fontSize: "16px" }}
                              className="btn btn-sm button-primary px-2 mt-2"
                              onClick={() => {
                                append({});
                              }}
                            >
                              <FontAwesomeIcon
                                style={{
                                  color: "#ffffff",
                                }}
                                icon={faAdd}
                                size="sm"
                              />
                              {"Add"}
                            </button>
                          )} */}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={"form-control form-control-sm"}
                            type="text"
                            name={`items[${index}].insp_name`}
                            {...register(`items[${index}].insp_name`)}
                            placeholder="Enter inspection name"
                          />

                          {/* {errors.items?.[index]?.insp_name && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.insp_name.message}
                              </span>
                            )} */}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <Controller
                            name={`items[${index}].inspection_date`}
                            control={control}
                            // defaultValue={selDate !== undefined && selDate !== null ? selDate : null}
                            render={({ field }) => (
                              <DatePicker
                                className={"form-control form-control-sm"}
                                {...field}
                                showYearDropdown
                                scrollableMonthYearDropdown
                                dateFormat="dd/MM/yyyy"
                                selected={field.value}
                                // maxDate={new Date()}
                                placeholderText="Select date"
                                autoComplete="off"
                              />
                            )}
                          />
                          {/* {errors.items?.[index]?.inspection_date && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.inspection_date.message}
                            </span>
                          )} */}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.inspection_remark
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].inspection_remark`}
                            {...register(`items[${index}].inspection_remark`)}
                            placeholder="Enter inspection remark"
                          />

                          {/* {errors.items?.[index]?.inspection_remark && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.inspection_remark.message}
                              </span>
                            )} */}
                        </td>
                        <td
                          style={{
                            verticalAlign: "top",
                          }}
                        >
                          <Tooltip
                            id={"delete-tooltip" + index}
                            place="top"
                          />
                          <Trash2
                            data-tooltip-id={"delete-tooltip" + index}
                            data-tooltip-content={"Delete Item"}
                            className="menu_icon_red cursor_pointer"
                            size={20}
                            onClick={() => {
                                remove(index);
                              }
                            }
                          />
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <Inspection_Data
                            prod_attr={prod_attr}
                            currInd={index}
                            constLabel={constLabel}
                            inspListobj={inspListobj}
                            setInspListobj={setInspListobj}
                            action={"Create"}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
              disabled={lockBtn}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Create_Job_Card;
